import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class FeaturedCenter extends Component {
  render() {
    return (
      <div className="feature-box gray centered m-0 ">
        <div>
          <div className="container-fluid col-md-12 pt-3 pb-3">
            <div className="row justify-content-md-center">
              <div className="col-md-2">
                <div className="content-box">
                  <div className="image">
                    <img
                      src="img/demo/icons/realestate.png"
                      width={100}
                      alt=""
                    />
                  </div>
                  <h4>Realestate</h4>
                  <div className="caption">
                    Find all the realestates listed on a single place.
                  </div>
                  <div className="button">
                    <Link to="/realestate/list">View More</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="content-box">
                  <div className="image">
                    <img src="img/demo/icons/vehicle.png" width={100} alt="" />
                  </div>
                  <h4>Vehicles</h4>
                  <div className="caption">
                    Buy or Sell first or second hand vehicles.
                  </div>
                  <div className="button">
                    <Link to="/vehicle/list">View More</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="content-box">
                  <div className="image">
                    <img src="img/demo/icons/company.png" width={100} alt="" />
                  </div>
                  <h4>Company</h4>
                  <div className="caption">
                    Find Business for sale and rent under a same place.
                  </div>

                  <div className="button">
                    <Link to="/company/list">View More</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="content-box">
                  <div className="image">
                    <img src="img/demo/icons/jobs.png" width={100} alt="" />
                  </div>
                  <h4>Jobs</h4>
                  <div className="caption">
                    Find all the job vacancy under a single place.
                  </div>

                  <div className="button">
                    <Link to="/jobs/list">View More</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-2 ">
                <div className="content-box">
                  <div className="image">
                    <img src="img/demo/icons/services.png" width={100} alt="" />
                  </div>
                  <h4>Services</h4>
                  <div className="caption">
                    Get all the services online with one click.
                  </div>

                  <div className="button">
                    <Link to="/services/list">View More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
