import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const AllDistricts = ({ districts }) => {
  return (
    <div className="feature-box centered m-0 pt-4">
      <div className="container-fluid">
        <div className="row justify-content-md-center">
          {districts && (
            <div className="col col-lg-12 col-xl-11">
              <div className="main-title">
                <span>Find Properties Quickly</span>
              </div>
              <div className="clearfix" />
              <div className="row">
                {[
                  ...new Set(
                    districts.map((district) => {
                      return district.toLowerCase();
                    })
                  ),
                ].map((district) => {
                  return (
                    <div className="col-md-2">
                      <Card
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "0px !important",
                          background: "#03016D",
                          boxShadow: "none",
                        }}
                      >
                        <Link to={`realestate/list?city=${district}`}>
                          <h4 className="text-capitalize  text-light  ">
                            {district}
                          </h4>
                        </Link>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllDistricts;
