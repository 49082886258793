import React, { Component, Fragment } from "react";
import Alert from "react-bootstrap/Alert";
import Axios from "axios";
import Moment from "react-moment";
import Reply from "./Reply";
import { connect } from "react-redux";
import { withRouter } from "react-router";
class SingleComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      redirect: false,
      comment: "",
      isCommenting: false,
      reply: "",
    };
    if (this.props.loggedIn) {
      this.state.loggedIn = true;
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      isCommenting: true,
    });
    const data = {
      body: this.state.comment,
    };
    Axios.post(
      process.env.REACT_APP_API + "company/" + this.props.id + "/comment",
      data
    )
      .then((response) => {
        this.setState({
          comment: "",
          isCommenting: false,
        });
        this.props.getComments();
        this.props.history.push("/company/view/" + this.props.id);
      })
      .catch((error) => {
        this.setState({
          isCommenting: false,
        });
        console.log(error);
      });
  };

  render() {
    return (
      <div className="comments">
        <h3 className="subheadline">Comments</h3>

        {this.props.data.map((item) => {
          return (
            <Fragment key={item.comment_id}>
              <div className="container-fluid mb-4">
                <div className="be-comment">
                  <div className="be-img-comment">
                    {item.user.map((user) => {
                      return (
                        <Fragment key={user.id}>
                          <img
                            src={
                              process.env.REACT_APP_BACKEND_CONTENT +
                              "images/profilepictures/" +
                              user.photo
                            }
                            alt=""
                            className="be-ava-comment"
                          />
                        </Fragment>
                      );
                    })}
                  </div>
                  <div className="be-comment-content">
                    <span className="be-comment-name">
                      {item.user.map((user) => {
                        return user.firstname + " " + user.lastname;
                      })}
                    </span>
                    <span className="be-comment-time">
                      <i className="fa fa-clock-o px-2" />
                      <Moment fromNow ago>
                        {item.time}
                      </Moment>{" "}
                      ago
                    </span>
                    <p className="be-comment-text">{item.comment}</p>
                  </div>
                </div>
                <div className="container mt-4">
                  <div className="row justify-content-center  mt-3 mb-3">
                    <div className="col-md-11">
                      <div className="be-comment">
                        {item.replies.map((reply) => {
                          return (
                            <Fragment key={reply.id}>
                              <div className="be-img-comment">
                                <img
                                  src={
                                    process.env.REACT_APP_BACKEND_CONTENT +
                                    "images/profilepictures/" +
                                    reply.user[0].photo
                                  }
                                  alt=""
                                  className="be-ava-comment"
                                />
                              </div>
                              <div className="be-comment-content">
                                <span className="be-comment-name">
                                  {reply.user[0].firstname +
                                    " " +
                                    reply.user[0].lastname}
                                </span>
                                <span className="be-comment-time">
                                  <i className="fa fa-clock-o px-2" />
                                  <Moment fromNow ago>
                                    {reply.time}
                                  </Moment>{" "}
                                  ago
                                </span>
                                <p className="be-comment-text">{reply.reply}</p>
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>
                      {this.state.loggedIn ? (
                        <Reply
                          id={this.props.id}
                          comment_id={item.comment_id}
                          getComment={this.props.getComments}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </Fragment>
          );
        })}
        {this.state.loggedIn ? (
          <form onSubmit={this.handleSubmit}>
            <div className="comment-input-box  mt-2 mb-5" id="post-comment">
              <div className="form-group">
                <label htmlFor="comment">Post Your Comment</label>
                <textarea
                  id="comment"
                  name="comment"
                  className="form-control form-control-lg"
                  placeholder="Your Comment"
                  value={this.state.comment}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-md btn-primary">
                  {this.state.isCommenting && (
                    <i className="fa fa-spin fa-circle-o-notch px-2"></i>
                  )}{" "}
                  Post Comment
                </button>
              </div>
            </div>
          </form>
        ) : (
          <Alert variant={"warning"} className="mt-5 mb-5">
            <h5 className="">
              You must be logged in to post a comment or reply a post.
            </h5>
          </Alert>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};
export default connect(mapStateToProps, null)(withRouter(SingleComment));
