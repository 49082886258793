import React, { Component, Fragment } from "react";
import ImageGallery from "react-image-gallery";
import Badge from "react-bootstrap/Badge";
import { compose, withProps } from "recompose";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

class SingleViewBody extends Component {
  componentDidMount() {
    window.lib();
    window.lib2();
  }
  ListSingle = (data, name) => {
    return (
      <li className="my-2">
        {name}
        {data === "yes" ? (
          <Badge pill variant="success" className="mx-2 px-2 py-1">
            Yes
          </Badge>
        ) : (
          <Badge pill variant="danger" className="mx-2 px-2 py-1">
            No
          </Badge>
        )}
      </li>
    );
  };
  render() {
    const images = this.props.photos.map((item) => {
      const big =
        process.env.REACT_APP_BACKEND_CONTENT + "images/" + item.photos;
      return {
        original: big,
        thumbnail: big,
      };
    });

    const MyMapComponent = compose(
      withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" +
          process.env.REACT_APP_GOOGLE_KEY +
          "&sensor=false",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
      }),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap
        defaultZoom={13}
        defaultCenter={{
          lat:
            Number(this.props.data.latitude) === 0
              ? 27.7172
              : Number(this.props.data.latitude),
          lng:
            Number(this.props.data.longitude) === 0
              ? 85.324
              : Number(this.props.data.longitude),
        }}
      >
        {props.isMarkerShown && (
          <Marker
            position={{
              lat: Number(this.props.data.latitude),
              lng: Number(this.props.data.longitude),
            }}
          />
        )}
      </GoogleMap>
    ));

    return (
      <Fragment>
        <div className="container-fluid col-md-11">
          <div className="row justify-content-md-center">
            <div className="col col-md-10 col-lg-10 col-xl-10">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/jobs/list">Jobs</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {this.props.data.job_title}
                </li>
              </ol>
              <div className="page-header bordered mb0">
                <div className="row">
                  <div className="col-md-8">
                    <a
                      onClick={() => this.props.history.goBack()}
                      className="btn-return"
                      title="Back"
                    >
                      <i className="fa fa-angle-left" />
                    </a>
                    <h1>
                      {this.props.data.job_title}
                      {/* <span className="label label-bordered  p-2  mx-2">
                        {this.props.data.price_status}
                      </span> */}
                      <small>
                        <i className="fa fa-map-marker px-2 pt-1" />
                        {this.props.data.address}, {this.props.data.district}
                      </small>
                    </h1>
                  </div>
                  <div className="col-md-4">
                    <div className="price">
                      {this.props.data.company_name}
                      <small> Type: {this.props.data.category}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="content" className="item-single">
          <div className="container-fluid col-md-11">
            <div className="row justify-content-md-center">
              <div className="col col-md-12 col-lg-12 col-xl-10">
                <div className="row row justify-content-md-center has-sidebar">
                  <div className="col-md-7 col-lg-8">
                    <ImageGallery
                      items={images}
                      showPlayButton={false}
                      useTranslate3D={false}
                      flickThreshold={0.1}
                      lazyLoad={true}
                      showThumbnails={false}
                    />
                    <div>
                      <div>
                        <ul className="item-features mt-3 text-center">
                          <li>
                            <span className="text-center">
                              {this.props.data.working_hours}
                            </span>
                            Working Hours
                          </li>
                          <li className="text-center">
                            <span>{this.props.data.vacancy_number}</span>
                            No of Vacancy
                          </li>
                          <li className="text-center">
                            <span> {"Rs " + this.props.data.job_salary}</span>
                            Salary
                          </li>
                        </ul>
                        <h3 className="headline">Features</h3>
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td colSpan="2">
                                <span className="featured-tab">
                                  <i className="fa fa fa-angle-double-right  px-2"></i>
                                  Job Requirements
                                </span>
                                <br />
                                <span className="featured-data pl-4">
                                  {this.props.data.job_requirements}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2">
                                <span className="featured-tab">
                                  <i className="fa fa fa-angle-double-right  px-2"></i>
                                  Available Position
                                </span>
                                <br />
                                <span className="featured-data pl-4">
                                  {this.props.data.available_position}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="featured-tab">
                                  <i className="fa fa fa-angle-double-right  px-2"></i>
                                  Working Hours
                                </span>
                                <br />
                                <span className="featured-data pl-4">
                                  {this.props.data.working_hours + "hours"}
                                </span>
                              </td>
                              <td>
                                <span className="featured-tab">
                                  <i className="fa fa fa-angle-double-right  px-2"></i>
                                  Experience
                                </span>
                                <br />
                                <span className="featured-data pl-4">
                                  {this.props.data.experience_years + "years"}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="item-description">
                          <h3 className="headline">Job Description</h3>

                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.props.data.description,
                            }}
                          ></div>
                        </div>

                        <h3 className="headline">Other Features</h3>
                        <ul className="checked_list feature-list">
                          {this.ListSingle(this.props.data.gym, "Gym")}
                          {this.ListSingle(
                            this.props.data.health,
                            "Health Insurance"
                          )}
                          {this.ListSingle(this.props.data.lunch, "Lunch")}
                          {this.ListSingle(this.props.data.device, "Device")}
                        </ul>
                        <div className="item-navigation">
                          <ul className="nav nav-tabs v2" role="tablist">
                            <li role="presentation">
                              <a
                                href="#map"
                                aria-controls="map"
                                role="tab"
                                data-toggle="tab"
                                className="active"
                              >
                                <i className="fa fa-map px-2" />
                                <span className="hidden-xs">
                                  Map &amp; nearby
                                </span>
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div
                              role="tabpanel"
                              className="tab-pane active"
                              id="map"
                            >
                              <MyMapComponent isMarkerShown />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-4">
                    <div id="sidebar" className="sidebar-right">
                      <div className="sidebar_inner">
                        <div id="feature-list" role="tablist">
                          <div className="card">
                            <div
                              className="card-header"
                              role="tab"
                              id="headingOne"
                            >
                              <h4 className="panel-title">
                                <a
                                  role="button"
                                  data-toggle="collapse"
                                  href="#specification"
                                  aria-expanded="true"
                                  aria-controls="specification"
                                >
                                  Specifications
                                  <i className="fa fa-caret-down float-right" />
                                </a>
                              </h4>
                            </div>
                            <div
                              id="specification"
                              className="panel-collapse collapse show"
                              role="tabpanel"
                            >
                              <div className="card-body">
                                <table className="table v1">
                                  <tbody>
                                    <tr>
                                      <td>Salary</td>
                                      <td>{this.props.data.job_salary}</td>
                                    </tr>
                                    <tr>
                                      <td>Salary Status</td>
                                      <td>
                                        {this.props.data.salary_status ===
                                        "fixed"
                                          ? "Fixed"
                                          : "Negotiable"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Experience</td>
                                      <td>
                                        {this.props.data.experience_years +
                                          " years"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Working Hours</td>
                                      <td>{this.props.data.working_hours}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(SingleViewBody);
