import React, { Component } from "react";
import Navbar from "../../partials/Navbar";
import Footer from "../../partials/Footer";
import Sidebar from "../partials/Sidebar";
import MyProfileBody from "./MyProfileBody";

class MyProfile extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <div className="clearfix"></div>
        <div id="content">
          <div className="container-fluid col-md-11">
            <div className="row justify-content-md-center">
              <div className="col col-lg-12 col-xl-10">
                <div className="row has-sidebar">
                  <Sidebar />
                  <MyProfileBody />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default MyProfile;
