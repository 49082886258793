import React, { Component, Fragment } from "react";
import Navbar from "../../../partials/Navbar";
import Footer from "../../../partials/Footer";
import Axios from "axios";
import queryString from "query-string";
import { Link } from "react-router-dom";
import GridView from "./GridView";
import Pagination from "react-js-pagination";
import JobsSEO from "../JobsSEO";

export default class JobsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: false,
      isLoading: false,
      isFiltering: false,
      maxPrice: "",
      minPrice: "",
      searched: false,
      links: [],
      address: "",
      page: 1,
      currentPage: "",
      perPage: "",
      totalPage: "",
      category: "",
      city: "",
      data: [],
      categorylist: [],
      search: "",
      district: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
  }
  componentDidMount() {
    window.toggle();
    window.sidebarDefault();
    const values = queryString.parse(this.props.location.search);
    console.log(values);
    if (values.search) {
      this.setState({
        search: values.search,
        page: values.page,
      });
      this.searchApiFirst(values.search, values.page);
    } else {
      this.allData(values, this.state.page);
    }
    this.jobsCategory();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      const values = queryString.parse(this.props.location.search);
      if (values.search) {
        this.setState({
          search: values.search,
          page: values.page,
        });
        this.searchApiFirst(values.search, values.page);
      } else {
        this.setState({
          searching: false,
        });
        this.allData(values, this.state.page);
      }
    }
  }
  jobsCategory = () => {
    Axios.get(process.env.REACT_APP_API + "jobcategory")
      .then((response) => {
        this.setState({
          categorylist: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  filter = (data, pageNumber) => {
    this.setState({
      isLoading: true,
      maxPrice: data.maxprice ? data.maxprice : undefined,
      minPrice: data.minprice ? data.minprice : undefined,
      address: data.address ? data.address : undefined,
      city: data.city ? data.city : undefined,
      category: data.category ? data.category : undefined,
      district: data.district ? data.district : undefined,
      search: "",
    });

    Axios.post(
      process.env.REACT_APP_API + "jobs/search?page=" + pageNumber,
      data
    )
      .then((response) => {
        this.setState({
          isLoading: false,
          data: response.data.data,
          searched: false,
          currentPage: response.data.meta.current_page,
          perPage: response.data.meta.per_page,
          totalPage: response.data.meta.total,
        });

        this.props.history.push(
          "?" +
            queryString.stringify(data, {
              skipEmptyString: true,
            })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  allData = (data, pageNumber) => {
    this.setState({
      isLoading: true,
      maxPrice: data.maxprice ? data.maxprice : undefined,
      minPrice: data.minprice ? data.minprice : undefined,
      address: data.address ? data.address : undefined,
      city: data.city ? data.city : undefined,
      category: data.category ? data.category : undefined,
      district: data.district ? data.district : undefined,
      search: "",
    });

    Axios.post(
      process.env.REACT_APP_API + "jobs/search?page=" + pageNumber,
      data
    )
      .then((response) => {
        this.setState({
          isLoading: false,
          data: response.data.data,
          searched: false,
          currentPage: response.data.meta.current_page,
          perPage: response.data.meta.per_page,
          totalPage: response.data.meta.total,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  searchApi = (data, pageNumber) => {
    Axios.post(process.env.REACT_APP_API + "jobs/filter?page=" + pageNumber, {
      search: data,
    })
      .then((response) => {
        this.setState({
          isLoading: false,
          isSearching: false,
          data: response.data.data,
          currentPage: response.data.meta.current_page,
          perPage: response.data.meta.per_page,
          totalPage: response.data.meta.total,
          page: pageNumber,
          searched: true,
        });
        this.props.history.push(
          "?" +
            queryString.stringify(
              {
                search: this.state.search,
                page: this.state.page,
              },
              {
                skipNull: true,
                skipEmptyString: true,
              }
            )
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isSearching: false,
        });
      });
  };
  searchApiFirst = (data, pageNumber) => {
    Axios.post(process.env.REACT_APP_API + "jobs/filter?page=" + pageNumber, {
      search: data,
    })
      .then((response) => {
        this.setState({
          isLoading: false,
          isSearching: false,
          data: response.data.data,
          currentPage: response.data.meta.current_page,
          perPage: response.data.meta.per_page,
          totalPage: response.data.meta.total,
          page: pageNumber,
          searched: true,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isSearching: false,
        });
      });
  };
  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.setState({
      search: this.state.search,
      isSearching: true,
    });
    this.searchApi(this.state.search);
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const submitData = {
      maxprice: this.state.maxPrice,
      minprice: this.state.minPrice,
      address: this.state.address,
      category: this.state.category,
      district: this.state.district,
      city: this.state.city,
    };
    this.filter(submitData, this.state.page);
  };
  getData = (pageNumber) => {
    this.setState({
      isLoading: true,
      page: pageNumber,
    });
    if (this.state.searched) {
      this.searchApi(this.state.search, pageNumber);
    } else {
      this.filter(
        {
          maxprice: this.state.maxPrice,
          minprice: this.state.minPrice,
          address: this.state.address,
          city: this.state.city,
          district: this.state.district,
          category: this.state.category,
          page: pageNumber,
        },
        pageNumber
      );
    }
  };

  render() {
    return (
      <div>
        <Navbar />
        <JobsSEO />
        <div className="container-fluid col-md-10">
          <div className="search-form">
            <div className="card">
              <div className="row">
                <div className="col-lg-10">
                  <div className="form-group">
                    <input
                      type="text"
                      name="search"
                      defaultValue={this.state.search}
                      id="search"
                      onChange={this.handleChange}
                      className="form-control form-control-lg"
                      placeholder="Address, District, Category, Name..."
                    />
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="row justify-content-center">
                    <div className="form-group ">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary btn-block"
                        onClick={this.handleSearchSubmit}
                      >
                        {this.state.isSearching && (
                          <i className="fa fa-refresh fa-spin px-2"></i>
                        )}
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid col-md-11">
          <div id="content" className="pt0 pb0">
            <div className="row justify-content-md-center">
              <div className="col col-md-10 col-xl-10">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/jobs/grid">Jobs</Link>
                    </li>
                    <li className="breadcrumb-item">Grid</li>
                  </ol>
                </nav>
                <div className="page-header">
                  <h1>Jobs</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid col-md-11">
            <div className="row justify-content-md-center">
              <div className="col col-lg-11 col-xl-11">
                <div className="row has-sidebar">
                  <div className="col-md-4 col-lg-3">
                    <button
                      id="toggle-filters"
                      onClick={window.toggle()}
                      className="btn btn-primary btn-circle mobile-filter"
                    >
                      <i className="fa fa-filter" />
                    </button>
                    <div id="sidebar" className="sidebar-left">
                      <button className="close-panel btn btn-white">
                        <i className="fa fa-long-arrow-left" />
                      </button>
                      <div className="sidebar_inner">
                        <div id="filters">
                          <div className="card">
                            <div className="card-header">
                              <h4 className="panel-title">
                                <a
                                  role="button"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  href="#p_features"
                                  aria-expanded="true"
                                  aria-controls="p_features"
                                >
                                  Category
                                  <i className="fa fa-caret-down float-right" />
                                </a>
                              </h4>
                            </div>
                            <div
                              id="p_features"
                              className="panel-collapse collapse "
                              role="tabpanel"
                            >
                              <div className="card-body mb-2">
                                {this.state.categorylist.map((category) => {
                                  return (
                                    <Fragment key={category.id}>
                                      <div className="listing pb-2 ">
                                        <u>
                                          <button
                                            className="btnStyle"
                                            onClick={() => {
                                              this.props.history.push(
                                                "/jobs/list?category=" +
                                                  encodeURIComponent(
                                                    category.category_name
                                                  )
                                              );
                                            }}
                                          >
                                            {category.category_name}
                                          </button>
                                        </u>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="card py-3">
                            <div className="card-header">
                              <h4 className="panel-title">
                                <a
                                  role="button"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  href="#p_budget"
                                  aria-expanded="true"
                                  aria-controls="p_type"
                                >
                                  Filters
                                  <i className="fa fa-caret-down float-right" />
                                </a>
                              </h4>
                            </div>
                            <div
                              id="p_budget"
                              className="panel-collapse collapse show"
                              role="tabpanel"
                            >
                              <form method="POST" onSubmit={this.handleSubmit}>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label htmlFor="Address">
                                          Min Price
                                        </label>
                                        <input
                                          type="text"
                                          name="minPrice"
                                          className="form-control input-sm"
                                          defaultValue={this.state.minPrice}
                                          onChange={this.handleChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label htmlFor="Address">
                                          Max Price
                                        </label>
                                        <input
                                          type="text"
                                          name="maxPrice"
                                          onChange={this.handleChange}
                                          defaultValue={this.state.maxPrice}
                                          className="form-control input-sm"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label htmlFor="Address">Address</label>
                                        <input
                                          type="text"
                                          defaultValue={this.state.address}
                                          onChange={this.handleChange}
                                          className="form-control "
                                          id="address"
                                          name="address"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label htmlFor="City">City</label>
                                        <input
                                          type="text"
                                          defaultValue={this.state.city}
                                          onChange={this.handleChange}
                                          className="form-control "
                                          id="city"
                                          name="city"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label htmlFor="inputState">
                                          District
                                        </label>
                                        <input
                                          type="text"
                                          defaultValue={this.state.district}
                                          onChange={this.handleChange}
                                          className="form-control mb-3"
                                          id="district"
                                          name="district"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-12 text-center">
                                      <div className="form-group">
                                        <button
                                          type="submit"
                                          className="btn btn-primary btn-md  "
                                          disabled={this.state.isFiltering}
                                        >
                                          {this.state.isFiltering && (
                                            <i className="fa fa-refresh fa-spin px-2"></i>
                                          )}
                                          Filter
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-9">
                    <div className="sorting">
                      <div className="row justify-content-between">
                        <div className="col-sm-5 col-md-5 col-lg-4 col-xl-3">
                          <div className="form-group">
                            {/* <select className="form-control ui-select">
                              <option>Most recent</option>
                              <option>Highest price</option>
                              <option>Lowest price</option>
                              <option>Most reduced</option>
                              <option>Most popular</option>
                            </select> */}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
                          <div className="btn-group float-right" role="group">
                            <Link to="/jobs/map" className="btn btn-light ">
                              <i className="fa fa-street-view" />
                            </Link>
                            <Link
                              to="/jobs/grid"
                              className="btn btn-light active"
                            >
                              <i className="fa fa-th" />
                            </Link>

                            <Link to="/jobs/list" className="btn btn-light ">
                              <i className="fa fa-bars" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="item-listing grid">
                      <div class="row">
                        <GridView
                          data={this.state.data}
                          isLoading={this.state.isLoading}
                        />
                      </div>
                    </div>
                    <nav aria-label="Page navigation">
                      <ul className="pagination">
                        {!this.state.isLoading &&
                          this.state.data.length !== 0 && (
                            <Pagination
                              activePage={Number(this.state.currentPage)}
                              itemsCountPerPage={Number(this.state.perPage)}
                              totalItemsCount={Number(this.state.totalPage)}
                              onChange={this.getData}
                              prevPageText="«"
                              nextPageText="»"
                              hideFirstLastPages={true}
                            />
                          )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
