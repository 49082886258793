import React from "react";
import spinner from "./spinner.gif";

export const SpinnerMap = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center "
      style={{ height: 500 }}
    >
      <img
        src={spinner}
        style={{ width: "50px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    </div>
  );
};
