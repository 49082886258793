import React, { Component } from "react";
import Navbar from "../../partials/Navbar";
import Footer from "../../partials/Footer";
import Sidebar from "../partials/Sidebar";
import MyListingBody from "./MyListingBody";
import { connect } from "react-redux";
import { withRouter } from "react-router";
class MyListing extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <div className="clearfix"></div>
        <div id="content">
          <div className="container-fluid col-md-11">
            <div className="row justify-content-md-center">
              <div className="col col-lg-12 col-xl-10">
                <div className="row has-sidebar">
                  <Sidebar />
                  <MyListingBody />
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(withRouter(MyListing));
