import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
Geocode.enableDebug();
class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tole: "",
      address: "",
      city: "",
      district: "",
      country: "",
      zip_code: "",
      province: "",
      isLocationSet: true,
      isMapLoading: false,
      latitude: "",
      longitude: "",
    };
  }
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  updateMap = () => {
    if (this.props.validator1.allValid()) {
      this.setState({
        isMapLoading: true,
      });
      const address =
        this.state.tole +
        ", " +
        this.state.address +
        ", " +
        this.state.city +
        ", " +
        this.state.district +
        ", " +
        this.state.country +
        ", " +
        this.state.province +
        ", " +
        this.state.zip_code;
      Geocode.fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          this.setState({
            latitude: lat,
            longitude: lng,
            isMapLoading: false,
          });
          this.props.updateMap({ lat, lng }, this.state);
        },
        (error) => {
          alert(error);
          this.setState({ isMapLoading: false });
        }
      );
    } else {
      this.props.validator1.showMessages();
      this.forceUpdate();
    }
  };
  componentWillReceiveProps(nextProps) {
    this.setState({
      tole: nextProps.state.tole,
      address: nextProps.state.address,
      city: nextProps.state.city,
      district: nextProps.state.district,
      country: nextProps.state.country,
      province: nextProps.state.province,
      zip_code: nextProps.state.zip_code,
      latitude: nextProps.state.latitude,
      longitude: nextProps.state.longitude,
      isMapLoading: nextProps.state.isMapLoading,
    });
  }

  componentDidMount() {
    this.getPropsData();
  }

  getPropsData = () => {
    this.setState({
      tole: this.props.state.tole,
      address: this.props.state.address,
      city: this.props.state.city,
      district: this.props.state.district,
      country: this.props.state.country,
      province: this.props.state.province,
      zip_code: this.props.state.zip_code,
      isMapLoading: this.props.isMapLoading,
    });
  };
  onInfoWindowClose = (event) => {};

  render() {
    {
      console.log(this.state);
    }
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.props.state.mapPosition.lat,
            lng: this.props.state.mapPosition.lng,
          }}
        >
          <Marker
            google={this.props.google}
            name={"Dolores park"}
            draggable={true}
            onDragEnd={this.props.onMarkerDragEnd}
            position={{
              lat: this.props.state.markerPosition.lat,
              lng: this.props.state.markerPosition.lng,
            }}
          />
          <Marker />
          {/* 
          <Autocomplete
            style={{
              width: "100%",
              marginTop: "10px",
              height: "50px",
              paddingLeft: "20px",
            }}
            onPlaceSelected={this.props.onPlaceSelected}
            types={["(regions)"]}
            componentRestrictions={{ country: "np" }}
          /> */}
        </GoogleMap>
      ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  Tole<span className="text-danger px-1">*</span>
                </label>
                <input
                  type="text"
                  onChange={this.onChange}
                  value={this.state.tole}
                  className="form-control form-control-lg"
                  id="tole"
                  onBlur={() => this.props.validator1.showMessageFor("tole")}
                  name="tole"
                />
                {this.props.validator1.message(
                  "tole",
                  this.state.tole,
                  "required"
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  Address<span className="text-danger px-1">*</span>
                </label>
                <input
                  type="text"
                  onChange={this.onChange}
                  value={this.state.address}
                  className="form-control form-control-lg"
                  id="address"
                  onBlur={() => this.props.validator1.showMessageFor("address")}
                  name="address"
                />
                {this.props.validator1.message(
                  "address",
                  this.state.address,
                  "required"
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  City<span className="text-danger px-1">*</span>
                </label>
                <input
                  type="text"
                  onChange={this.onChange}
                  value={this.state.city}
                  className="form-control form-control-lg"
                  id="city"
                  onBlur={() => this.props.validator1.showMessageFor("city")}
                  name="city"
                />
                {this.props.validator1.message(
                  "city",
                  this.state.city,
                  "required"
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  District<span className="text-danger px-1">*</span>
                </label>
                <input
                  type="text"
                  value={this.state.district}
                  onChange={this.onChange}
                  onBlur={() =>
                    this.props.validator1.showMessageFor("district")
                  }
                  className="form-control form-control-lg"
                  id="district"
                  name="district"
                />

                {this.props.validator1.message(
                  "district",
                  this.state.district,
                  "required"
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  Country <span className="text-danger px-1">*</span>
                </label>
                <input
                  type="text"
                  value={this.state.country}
                  onChange={this.onChange}
                  onBlur={() => this.props.validator1.showMessageFor("country")}
                  className="form-control form-control-lg"
                  id="country"
                  name="country"
                />

                {this.props.validator1.message(
                  "country",
                  this.state.country,
                  "required"
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="Province">
                  Province <span className="text-danger px-1">*</span>
                </label>
                <input
                  name="province"
                  id="province"
                  value={this.state.province}
                  onChange={this.onChange}
                  className="form-control form-control-lg "
                />
                {this.props.validator1.message(
                  "province",
                  this.state.province,
                  "required"
                )}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  Zipcode<span className="text-danger px-1">*</span>
                </label>
                <input
                  type="number"
                  onChange={this.onChange}
                  value={this.state.zip_code}
                  className="form-control form-control-lg"
                  id="zip_code"
                  name="zip_code"
                />
                {this.props.validator1.message(
                  "zip_code",
                  this.state.zip_code,
                  "required"
                )}
              </div>
              <div className="btn btn-secondary mb-3" onClick={this.updateMap}>
                {this.state.isMapLoading && (
                  <i className="fa fa-refresh fa-spin px-2"></i>
                )}
                {this.state.isMapLoading ? "Loading" : "Locate on Map"}
              </div>
            </div>
          </div>
          {this.props.state.showMap && (
            <React.Fragment>
              <h6 className="py-2">
                Please drag the marker to find the exact location.
              </h6>
              <AsyncMap
                googleMapURL={
                  "https://maps.googleapis.com/maps/api/js?key=" +
                  process.env.REACT_APP_GOOGLE_KEY +
                  "&libraries=places"
                }
                loadingElement={
                  <div style={{ height: `400px`, width: "100%" }}>
                    Loading Map..
                  </div>
                }
                containerElement={<div style={{ height: this.props.height }} />}
                mapElement={<div style={{ height: `400px`, width: "100%" }} />}
              />
            </React.Fragment>
          )}
        </div>
      );
    } else {
      map = <div style={{ height: `400px` }} />;
    }
    return map;
  }
}
export default Map;
