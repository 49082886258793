import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "../../../utilities/Spinner";
export default class GridView extends Component {
  render() {
    const items = this.props.data.map((key) => {
      return (
        <Fragment key={key.job_id}>
          <div className="col-lg-6  col-sm-8 col-xs-10">
            <div className="item">
              <div className="item-image">
                <Link to={"/jobs/view/" + key.job_id}>
                  <img
                    alt="jobs"
                    src={
                      process.env.REACT_APP_BACKEND_CONTENT +
                      "images/" +
                      key.photos
                    }
                    className="img-fluid"
                    style={{ height: 300, width: "100%" }}
                  />
                  <div className="item-meta">
                    <div className="item-meta">
                      <div className="item-price">Rs.{key.job_salary}</div>
                    </div>
                  </div>
                  <div className="item-badges">
                    <div className="item-badge-right">
                      {key.available_position}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="item-info">
                <h3 className="item-title mt-3">
                  <Link to={"/jobs/view/" + key.job_id}>{key.job_title}</Link>
                </h3>
                <div className="item-location">
                  <i className="fa fa-map-marker" /> {key.address},
                  {key.district}
                </div>
                <div className="item-details-i">
                  <span
                    className="bedrooms"
                    data-toggle="tooltip"
                    title="Working Hours"
                  >
                    {key.working_hours}
                    <i className="fa fa-clock-o px-2" />
                  </span>
                  <span
                    className="bathrooms"
                    data-toggle="tooltip"
                    title="Condition"
                  >
                    {key.condition}
                    <i className="fa fa-exclamation-circle px-2" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    });
    return (
      <Fragment>
        {items.length && !this.props.isLoading ? (
          items
        ) : this.props.isLoading ? (
          <div className="col-lg-12  ">
            <Spinner />
          </div>
        ) : (
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <h5>No data found in the record.</h5>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
