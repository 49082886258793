import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactPlayer from "react-player";
import OwlCarousel from "react-owl-carousel";
import { Spinner } from "react-bootstrap";

class HomeSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      category: "realestate",
      thumbnail: "",
      isLoading: true,
      minPrice: "",
      maxPrice: "",
      location: "",
    };
  }

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.props.history.push(
      "/" +
        this.state.category +
        "/list?address=" +
        this.state.search +
        "&minprice=" +
        this.state.minPrice +
        "&maxprice=" +
        this.state.maxPrice +
        "&district=" +
        this.state.location
    );
  };
  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const districts = [
      "Achham",
      "Arghakhanchi",
      "Baglung",
      "Baitadi",
      "Bajhang",
      "Bajura",
      "Banke",
      "Bara",
      "Bardiya",
      "Bhaktapur",
      "Bhojpur",
      "Chitwan",
      "Dadeldhura",
      "Dailekh",
      "Dang Deukhuri",
      "Darchula",
      "Dhading",
      "Dhankuta",
      "Dhanusa",
      "Dholkha",
      "Dolpa",
      "Doti",
      "Gorkha",
      "Gulmi",
      "Humla",
      "Ilam",
      "Jajarkot",
      "Jhapa",
      "Jumla",
      "Kailali",
      "Kalikot",
      "Kanchanpur",
      "Kapilvastu",
      "Kaski",
      "Kathmandu",
      "Kavrepalanchok",
      "Khotang",
      "Lalitpur",
      "Lamjung",
      "Mahottari",
      "Makwanpur",
      "Manang",
      "Morang",
      "Mugu",
      "Mustang",
      "Myagdi",
      "Nawalparasi",
      "Nuwakot",
      "Okhaldhunga",
      "Palpa",
      "Panchthar",
      "Parbat",
      "Parsa",
      "Pyuthan",
      "Ramechhap",
      "Rasuwa",
      "Rautahat",
      "Rolpa",
      "Rukum",
      "Rupandehi",
      "Salyan",
      "Sankhuwasabha",
      "Saptari",
      "Sarlahi",
      "Sindhuli",
      "Sindhupalchok",
      "Siraha",
      "Solukhumbu",
      "Sunsari",
      "Surkhet",
      "Syangja",
      "Tanahu",
      "Taplejung",
      "Terhathum",
      "Udayapur",
    ];
    return (
      <div className="home-search">
        <div className="main-banner-overlay">
          <OwlCarousel
            className="owl-carousel home-slider img element"
            items={1}
            loop
            responsiveClass={true}
            responsive={{
              0: {
                items: 1,
                dots: true,
              },
              600: {
                items: 1,
                dots: true,
              },
              1200: {
                items: 1,
                dots: true,
              },
            }}
          >
            {!this.state.isLoading ? (
              <Spinner />
            ) : (
              <React.Fragment>
                {this.props.data.map((data, key) => {
                  return (
                    <div className="item" key={key}>
                      {data.banner_type === "image" ? (
                        <img
                          className="slider-item img-fluid"
                          src={
                            process.env.REACT_APP_BACKEND_CONTENT +
                            "banner/" +
                            data.banner
                          }
                        />
                      ) : (
                        <div className="slider-item">
                          <ReactPlayer
                            url={
                              process.env.REACT_APP_BACKEND_CONTENT +
                              "banner/" +
                              data.banner
                            }
                            light={
                              process.env.REACT_APP_BACKEND_CONTENT +
                              "banner/thumbnails/" +
                              data.thumbnail
                            }
                            style={{
                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                            }}
                            height="100%"
                            width="100%"
                            controls={true}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </React.Fragment>
            )}
          </OwlCarousel>
        </div>
        <div className="search-form mt-0 pt-0 v4 home-search gray">
          <div className="hero-search">
            <div className="hero-warp">
              <div className="container-fluid col-md-11">
                <form
                  onSubmit={this.handleSearchSubmit}
                  className="main-search-form"
                >
                  <div className="search-type">
                    <div className="st-item">
                      <input
                        type="radio"
                        value="realestate"
                        name="category"
                        onClick={this.handleChange}
                        id="realestate"
                        defaultChecked
                      />
                      <label htmlFor="realestate">Realestate</label>
                    </div>
                    <div className="st-item">
                      <input
                        type="radio"
                        value="vehicle"
                        onClick={this.handleChange}
                        name="category"
                        id="vehicle"
                      />
                      <label htmlFor="vehicle">Vehicle</label>
                    </div>
                    <div className="st-item">
                      <input
                        type="radio"
                        value="company"
                        onClick={this.handleChange}
                        name="category"
                        id="company"
                      />
                      <label htmlFor="company">Company</label>
                    </div>
                    <div className="st-item">
                      <input
                        type="radio"
                        value="services"
                        onClick={this.handleChange}
                        name="category"
                        id="services"
                      />
                      <label htmlFor="services">Services</label>
                    </div>
                    <div className="st-item">
                      <input
                        type="radio"
                        value="jobs"
                        name="category"
                        onClick={this.handleChange}
                        id="jobs"
                      />
                      <label htmlFor="jobs">Jobs</label>
                    </div>
                  </div>
                  <div className="search-input">
                    <input
                      type="text"
                      name="search"
                      onChange={this.handleChange}
                      defaultValue={this.state.search}
                      className="form-control form-control-lg"
                      placeholder="Enter the address "
                    />
                    <button className=" btn  btn-primary site-btn px-5">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <select
                          onChange={this.handleChange}
                          name="location"
                          value={this.state.location}
                          className="form-control form-control-lg ui-select"
                        >
                          <option hidden value="">
                            District
                          </option>
                          {districts.map((item, key) => {
                            return (
                              <option
                                key={key}
                                className="text-capitalize"
                                value={item}
                              >
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <select
                          onChange={this.handleChange}
                          name="minPrice"
                          value={this.state.minPrice}
                          className="form-control form-control-lg ui-select"
                        >
                          <option hidden value="">
                            Min Price
                          </option>
                          <option value="0">0</option>
                          <option value="100">100</option>
                          <option value="1000">1000</option>
                          <option value="10000">10,000</option>
                          <option value="100000">1,00,000</option>
                          <option value="1000000">10,00,000</option>
                          <option value="10000000">1,00,00,000</option>
                          <option value="100000000">10,00,00,000</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <select
                          onChange={this.handleChange}
                          name="maxPrice"
                          value={this.state.maxPrice}
                          className="form-control form-control-lg ui-select"
                        >
                          <option hidden value="">
                            Max Price
                          </option>
                          <option value="0">0</option>
                          <option value="100">100</option>
                          <option value="1000">1000</option>
                          <option value="10000">10,000</option>
                          <option value="100000">1,00,000</option>
                          <option value="1000000">10,00,000</option>
                          <option value="10000000">1,00,00,000</option>
                          <option value="100000000">10,00,00,000</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(HomeSearch);
