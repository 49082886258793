import React, { Component, Fragment } from "react";
import ImageGallery from "react-image-gallery";

import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

class SingleViewBody extends Component {
  componentDidMount() {
    window.lib();
    window.lib2();
  }
  ListSingle = (data, name) => {
    return (
      <li className="my-2">
        {name}
        {data === "yes" ? (
          <Badge pill variant="success" className="mx-2 px-2 py-1">
            {data}
          </Badge>
        ) : (
          <Badge pill variant="danger" className="mx-2 px-2 py-1">
            {data}
          </Badge>
        )}
      </li>
    );
  };
  render() {
    const images = this.props.photos.map((item) => {
      const big =
        process.env.REACT_APP_BACKEND_CONTENT + "images/" + item.photos;
      return {
        original: big,
        thumbnail: big,
      };
    });

    const MyMapComponent = compose(
      withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" +
          process.env.REACT_APP_GOOGLE_KEY +
          "&sensor=false",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
      }),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap
        defaultZoom={13}
        defaultCenter={{
          lat:
            Number(this.props.data.latitude) === 0
              ? 27.7172
              : Number(this.props.data.latitude),
          lng:
            Number(this.props.data.longitude) === 0
              ? 85.324
              : Number(this.props.data.longitude),
        }}
      >
        {props.isMarkerShown && (
          <Marker
            position={{
              lat: Number(this.props.data.latitude),
              lng: Number(this.props.data.longitude),
            }}
          />
        )}
      </GoogleMap>
    ));

    return (
      <Fragment>
        <div className="container-fluid col-md-11">
          <div className="row justify-content-md-center">
            <div className="col col-md-10 col-lg-10 col-xl-10">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/realestate/list">Property for Sale</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {this.props.data.property_name}
                </li>
              </ol>
              <div className="page-header bordered mb0">
                <div className="row">
                  <div className="col-md-8">
                    <a
                      onClick={() => this.props.history.goBack()}
                      className="btn-return"
                      title="Back"
                    >
                      <i className="fa fa-angle-left" />
                    </a>
                    <h1>
                      {this.props.data.property_name}
                      <span className="label label-bordered  p-2  mx-2">
                        For {this.props.data.property_status}
                      </span>
                      <small>
                        <i className="fa fa-map-marker px-2 pt-1" />
                        {this.props.data.address}, {this.props.data.district}
                      </small>
                    </h1>
                  </div>
                  <div className="col-md-4">
                    <div className="price">
                      Rs. {this.props.data.property_price}
                      <small> {this.props.data.category}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="content" className="item-single">
          <div className="container-fluid col-md-11">
            <div className="row justify-content-md-center">
              <div className="col col-md-12 col-lg-12 col-xl-10">
                <div className="row row justify-content-md-center has-sidebar">
                  <div className="col-md-7 col-lg-8">
                    <ImageGallery
                      items={images}
                      showPlayButton={false}
                      useTranslate3D={false}
                      flickThreshold={0.1}
                      lazyLoad={true}
                    />
                    <div>
                      <div>
                        <ul className="item-features mt-3 ">
                          <li>
                            <span className="text-center">
                              {this.props.data.number_of_kitchens}
                            </span>
                            Kitchens
                          </li>
                          <li className="text-center">
                            <span>{this.props.data.number_of_floors}</span>
                            Floors
                          </li>
                          <li className="text-center">
                            <span>{this.props.data.number_of_bedrooms}</span>
                            Bedrooms
                          </li>
                          <li className="text-center">
                            <span>{this.props.data.number_of_bathrooms}</span>
                            Bathrooms
                          </li>
                        </ul>
                        <div className="item-description">
                          <h3 className="headline">Property Description</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.props.data.description,
                            }}
                          ></div>
                        </div>

                        <h3 className="headline">Property Features</h3>
                        <ul className="checked_list feature-list">
                          {this.ListSingle(this.props.data.alarm, "Alarm")}
                          {this.ListSingle(this.props.data.garden, "Garden")}
                          {this.ListSingle(this.props.data.parking, "Parking")}
                          {this.ListSingle(
                            this.props.data.swimming_pool,
                            "Swimming Pool"
                          )}
                          {this.ListSingle(this.props.data.gym, "Gym")}
                          {this.ListSingle(
                            this.props.data.internet,
                            "Internet"
                          )}
                          {this.ListSingle(this.props.data.water, "Water")}
                          {this.ListSingle(
                            this.props.data.school_college_nearby,
                            "School/College Nearby"
                          )}
                          {this.ListSingle(
                            this.props.data.shopping_nearby,
                            "Shopping/Grocery Nearby"
                          )}
                          {this.ListSingle(
                            this.props.data.bank_nearby,
                            "Bank Nearby"
                          )}
                          {this.ListSingle(
                            this.props.data.pitched_road,
                            "Pitched Road"
                          )}
                          {this.ListSingle(
                            this.props.data.airport_nearby,
                            "Airport"
                          )}
                          {this.ListSingle(this.props.data.sewage, "Sewage")}
                          {this.ListSingle(this.props.data.cctv, "CCTV")}
                        </ul>
                        <div className="item-navigation">
                          <ul className="nav nav-tabs v2" role="tablist">
                            <li role="presentation">
                              <a
                                href="#map"
                                aria-controls="map"
                                role="tab"
                                data-toggle="tab"
                                className="active"
                              >
                                <i className="fa fa-map" />{" "}
                                <span className="hidden-xs">
                                  Map &amp; nearby
                                </span>
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div
                              role="tabpanel"
                              className="tab-pane active"
                              id="map"
                            >
                              <MyMapComponent isMarkerShown />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-4">
                    <div id="sidebar" className="sidebar-right">
                      <div className="sidebar_inner">
                        <div id="feature-list" role="tablist">
                          <div className="card">
                            <div
                              className="card-header"
                              role="tab"
                              id="headingOne"
                            >
                              <h4 className="panel-title">
                                <a
                                  role="button"
                                  data-toggle="collapse"
                                  href="#specification"
                                  aria-expanded="true"
                                  aria-controls="specification"
                                >
                                  Specifications
                                  <i className="fa fa-caret-down float-right" />
                                </a>
                              </h4>
                            </div>
                            <div
                              id="specification"
                              className="panel-collapse collapse show"
                              role="tabpanel"
                            >
                              <div className="card-body">
                                <table className="table v1">
                                  <tbody>
                                    <tr>
                                      <td>Area</td>
                                      <td>{this.props.data.property_area}</td>
                                    </tr>
                                    <tr>
                                      <td>Area Type</td>
                                      <td>{this.props.data.area_type}</td>
                                    </tr>
                                    <tr>
                                      <td>Mohoda Length</td>
                                      <td>{this.props.data.mohoda_length}</td>
                                    </tr>
                                    <tr>
                                      <td>Mohoda Direction</td>
                                      <td>
                                        {this.props.data.mohoda_direction}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card shadow">
                          <h5 className="subheadline mt-0  mb-0">Posted By</h5>
                          {this.props.user.map((user) => {
                            return (
                              <Fragment key={user.id}>
                                <div className="media">
                                  <div className="media-left">
                                    <img
                                      className="media-object rounded-circle"
                                      src={
                                        process.env.REACT_APP_BACKEND_CONTENT +
                                        "images/profilepictures/" +
                                        user.photo
                                      }
                                      width={64}
                                      height={64}
                                      alt=""
                                    />{" "}
                                  </div>
                                  <div className="media-body">
                                    <h4 className="media-heading user-profile">
                                      {user.firstname + " " + user.lastname}
                                    </h4>
                                  </div>
                                </div>
                                <p className="mt-3">
                                  <a>
                                    <i
                                      className="fa fa-envelope px-2"
                                      aria-hidden="true"
                                    />
                                    {user.email}
                                  </a>
                                </p>
                                <p>
                                  <a href={"tel:" + user.phone}>
                                    <i
                                      className="fa fa-phone px-2"
                                      aria-hidden="true"
                                    />
                                    {user.phone}
                                  </a>
                                </p>
                              </Fragment>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(SingleViewBody);
