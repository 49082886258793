import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Spinner } from "../../utilities/Spinner";
import Pagination from "react-js-pagination";
import queryString from "query-string";
import { withRouter } from "react-router";
import Axios from "axios";

class MyListingBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isLoading: false,
      data: [],
      currentPage: "",
      perPage: "",
      totalPage: "",
    };
  }
  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    if (values.page) {
      this.setState(
        {
          page: values.page,
        },
        () => this.getDataListing()
      );
    } else {
      this.getDataListing();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const values = queryString.parse(this.props.location.search);
    const prevValues = queryString.parse(prevProps.location.search);

    if (values.page !== prevValues.page) {
      this.setState(
        {
          page: values.page,
        },
        () => this.getDataListing()
      );
    }
  }
  getDataListing = () => {
    this.setState({
      isLoading: true,
    });
    Axios.get(process.env.REACT_APP_API + "mylisting?page=" + this.state.page)
      .then((response) => {
        console.log(response);
        this.setState({
          isLoading: false,
          data: response.data.data,
          currentPage: response.data.current_page,
          perPage: response.data.per_page,
          totalPage: response.data.total,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };

  getData = (pageNumber) => {
    this.setState(
      {
        page: pageNumber,
      },
      () => {
        this.getDataListing();
        this.props.history.push("/user/my-listing?page=" + this.state.page);
      }
    );
  };
  render() {
    return (
      <div className="col-md-7 col-lg-8 col-xl-8">
        <div className="page-header bordered">
          <h1>My Listing </h1>
        </div>
        {this.state.isLoading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <div className="item-listing list">
            {Object.values(this.state.data).map((key) => {
              return (
                <div key={key.id}>
                  <div className="item" key={key.id}>
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="item-image">
                          <Link to={"/" + key.listing_type + "/view/" + key.id}>
                            <img
                              src={
                                process.env.REACT_APP_BACKEND_CONTENT +
                                "images/" +
                                key.photos
                              }
                              alt="listing"
                              className="img-fluid"
                            />
                            <div className="item-meta">
                              <div className="item-price">
                                Rs.{key.listing_price}
                              </div>
                            </div>
                          </Link>
                        </div>
                        <Link
                          to={"/" + key.listing_type + "/edit/" + key.id}
                          className="remove-item"
                        >
                          <i className="fa fa-edit" /> Edit Listing
                        </Link>{" "}
                      </div>
                      <div className="col-lg-7">
                        <div className="item-info">
                          <h3 className="item-title">
                            <Link
                              to={"/" + key.listing_type + "/view/" + key.id}
                            >
                              {key.listing_name}
                            </Link>
                          </h3>
                          <div className="item-location">
                            <i className="fa fa-map-marker" />{" "}
                            {key.listing_city}, {key.listing_district}
                          </div>

                          <div className="item-details">
                            <ul>
                              <li>
                                Address <span>{key.listing_address}</span>
                              </li>
                              <li>
                                Type <span>{key.listing_type}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="added-on">
                              Listed on:
                              <Moment format="YYYY/MM/DD">
                                {key.listed_on}
                              </Moment>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <nav aria-label="Page navigation">
          <ul className="pagination">
            {!this.state.isLoading && this.state.data.length !== 0 && (
              <Pagination
                activePage={Number(this.state.currentPage)}
                itemsCountPerPage={Number(this.state.perPage)}
                totalItemsCount={Number(this.state.totalPage)}
                onChange={this.getData}
                prevPageText="«"
                nextPageText="»"
                hideFirstLastPages={true}
              />
            )}
          </ul>
        </nav>
      </div>
    );
  }
}
export default withRouter(MyListingBody);
