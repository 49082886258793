import React from "react";
import { Helmet } from "react-helmet";
import Axios from "axios";

export default class SEO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    Axios.get(process.env.REACT_APP_API + "metadata")
      .then((response) => {
        this.setState({ data: response.data.data });
      })
      .catch((error) => console.log(error));
  }
  render() {
    const keywords = this.state.data.map((item) => {
      return item.keywords;
    });

    return <div className="application"></div>;
  }
}
