import React, { Component, Fragment } from "react";
import SingleViewBody from "./SingleViewBody";
import SingleComment from "./SingleComment";
import Navbar from "../../../partials/Navbar";
import Footer from "../../../partials/Footer";
import Axios from "axios";
import { Spinner } from "../../../utilities/Spinner";
import ServiceSEO from "../ServiceSEO";

export default class SingleView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      photos: [],
      documents: [],
      isLoading: false,
      comments: [],
      user: [],
    };
  }

  componentDidMount() {
    window.sidebarDefault();

    this.getData();
    this.getComments();
  }
  getData = () => {
    this.setState({ isLoading: true });
    Axios.get(
      process.env.REACT_APP_API + "service/" + this.props.match.params.id
    )
      .then((response) => {
        this.setState({
          isLoading: false,
          data: response.data.data,
          photos: response.data.data.photos,
          documents: response.data.data.documents,
          user: response.data.data.user,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };
  getComments = () => {
    this.setState({ isLoading: true });

    Axios.get(
      process.env.REACT_APP_API +
        "service/" +
        this.props.match.params.id +
        "/comment"
    )
      .then((response) => {
        this.setState({ comments: response.data, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };
  render() {
    const url = window.location.href;
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    const twitterUrl = `https://twitter.com/intent/tweet?url=${url}`;
    return (
      <Fragment>
        <Navbar />
        <ServiceSEO />
        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <Fragment>
            <SingleViewBody
              id={this.props.match.params.id}
              data={this.state.data}
              photos={this.state.photos}
              documents={this.state.documents}
              user={this.state.user}
            />
            <div className="container-fluid col-md-11">
              <div className="row justify-content-md-center">
                <div className="col col-md-10 col-lg-10 col-xl-10">
                  <div className="socal-share-buttons">
                    <h3 className="subheadline">Share this article on:</h3>
                    <a
                      href={facebookUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-social btn-facebook"
                    >
                      <i className="icon fa fa-facebook" /> Facebook
                    </a>
                    {/* <a href={goo className="btn btn-social btn-google">
                    <i className="icon fa fa-google" /> Google
                  </a> */}
                    <a
                      href={twitterUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-social btn-twitter"
                    >
                      <i className="icon fa fa-twitter" /> Twitter
                    </a>
                  </div>
                  <SingleComment
                    data={this.state.comments}
                    id={this.props.match.params.id}
                    getComments={this.getComments}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <Footer />
      </Fragment>
    );
  }
}
