import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Footer from "../partials/Footer";
import ForgetPasswordForm from "./ForgetPasswordForm";

export default class ForgetPassword extends Component {
  render() {
    return (
      <Fragment>
        <Navbar />
        <ForgetPasswordForm />
        <Footer />
      </Fragment>
    );
  }
}
