import React, { Component, Fragment } from "react";
import Axios from "axios";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";

import { NotificationManager } from "react-notifications";
import { Nepal } from "nepal_political";
import { scrollToTop } from "../..";
import { SuccessToast } from "../utilities/SuccessToast";

import "react-notifications-component/dist/theme.css";
class RegisterView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errors: false,
      registered: false,
      errorMessage: "",
      isNotValidated: "",
      terms: false,
      token: "",
      data: {
        email: "",
        firstname: "",
        lastname: "",
        address: "",
        district: "",
        city: "",
        password: "",
        phone: "",
        confirmpassword: "",
      },
    };
    this.validator = new SimpleReactValidator({
      messages: {
        in: "Password Confirmation should match with password",
      },

      autoForceUpdate: this,
    });
    this.handleChange = this.handleChange.bind(this);
  }
  resendMail = (e) => {
    Axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.state.token;
    Axios.get(process.env.REACT_APP_API + "email/resend", {
      email: this.state.data.email,
    })
      .then((response) => {
        NotificationManager.success(
          "The verification mail has been successfully sent to your email."
        );
      })
      .catch((error) => {
        this.setState({
          errors: true,
          loading: false,
          errorMessage: error.response.data.message,
        });
        scrollToTop();
      });
  };
  handleChange = (event) => {
    const { data } = { ...this.state };
    const currentState = data;
    const { name, value } = event.target;
    currentState[name] = value;
    this.setState({ data: currentState });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        isNotValidated: false,
      });
      const data = {
        firstname: this.state.data.firstname,
        lastname: this.state.data.lastname,
        tole: this.state.data.address,
        city: this.state.data.city,
        district: this.state.data.district,
        password: this.state.data.password,
        password_confirmation: this.state.data.confirmpassword,
        phone: this.state.data.phone,
        email: this.state.data.email,
      };
      Axios.post(process.env.REACT_APP_API + "register", data)
        .then((response) => {
          console.log(response);
          this.setState({
            loading: false,
            token: response.data.token.access_token,
            registered: true,
          });
          NotificationManager.success("You have been successfully registered");
        })
        .catch((error) => {
          this.setState({
            errors: true,
            loading: false,
            errorMessage: error.response.data.message,
          });
          scrollToTop();
        });
    } else {
      this.validator.showMessages();
      this.setState({
        loading: false,
        isNotValidated: true,
      });
      this.forceUpdate();
      scrollToTop();
    }
  };
  render() {
    return (
      <Fragment>
        {SuccessToast()}
        {this.state.registered ? (
          <div id="content">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col col-lg-8">
                  <div className="error-template text-center">
                    <i
                      className="fa fa-check text-success animated zoomIn mb50"
                      style={{ fontSize: 100 }}
                    />
                    <h3 className="main-title">
                      <span>You have been successfully registered.</span>
                    </h3>
                    <div className="main-title-description">
                      You need to verify your email before login in to our
                      website. If you haven't received the verification email,
                      please{" "}
                      <a
                        style={{ color: "#03016D", fontWeight: "500" }}
                        onClick={(e) => this.resendMail(e)}
                      >
                        click here{" "}
                      </a>{" "}
                      to resend the email.
                    </div>
                    <div className="error-actions">
                      <Link
                        to="/login"
                        className="btn btn-primary btn-lg ml-2 mr-2 mb-5"
                      >
                        Go to Login
                      </Link>
                      <div className="main-title-description"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col col-md-12 col-lg-10 col-xl-8">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>

                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Register
                      </li>
                    </ol>
                  </nav>
                  <div className="page-header">
                    <h1>Please sign in or register</h1>
                  </div>
                </div>
              </div>
            </div>
            <div id="content" className="pt0 pb0">
              <div className="container">
                <div className="row justify-content-md-center align-items-center">
                  <div className="col col-md-8  col-lg-8 col-xl-8">
                    <ul className="nav nav-tabs tab-lg" role="tablist">
                      <li role="presentation" className="nav-item">
                        <Link to="/login" className="nav-link">
                          Sign In
                        </Link>
                      </li>
                      <li role="presentation" className="nav-item">
                        <a className="nav-link active" href="/register">
                          Register
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        role="tabpanel"
                        className="tab-pane active"
                        id="login"
                      >
                        {this.state.errors ? (
                          <div
                            className="alert alert-danger alert-dismissible fade show"
                            role="alert"
                          >
                            {this.state.errorMessage}
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                        <form onSubmit={this.handleSubmit} method="POST">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="name">
                                  First Name
                                  <span className="text-danger px-1">*</span>
                                </label>

                                <input
                                  type="text"
                                  id="firstname"
                                  name="firstname"
                                  value={this.state.data.firstname}
                                  onBlur={() =>
                                    this.validator.showMessageFor("First Name")
                                  }
                                  onChange={this.handleChange}
                                  className="  form-control form-control-lg"
                                  placeholder="First Name"
                                />

                                {this.validator.message(
                                  "First Name",
                                  this.state.data.firstname,
                                  "required|alpha"
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="lastname">
                                  Last Name
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="lastname"
                                  name="lastname"
                                  onBlur={() =>
                                    this.validator.showMessageFor("Last Name")
                                  }
                                  value={this.state.data.lastname}
                                  onChange={this.handleChange}
                                  className="form-control form-control-lg"
                                  placeholder="Last Name"
                                />
                                <div className="text-danger pb-2">
                                  {this.validator.message(
                                    "Last Name",
                                    this.state.data.lastname,
                                    "required|alpha_space"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">
                              Email address
                              <span className="text-danger px-1">*</span>
                            </label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              value={this.state.data.email}
                              onBlur={() =>
                                this.validator.showMessageFor("email")
                              }
                              onChange={this.handleChange}
                              className="form-control form-control-lg"
                              placeholder="Email"
                            />
                            <div className="text-danger pb-2">
                              {this.validator.message(
                                "email",
                                this.state.data.email,
                                "required|email"
                              )}
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="password">
                              Password
                              <span className="text-danger px-1">*</span>
                            </label>
                            <input
                              type="password"
                              id="password"
                              name="password"
                              onBlur={() =>
                                this.validator.showMessageFor("password")
                              }
                              value={this.state.data.password}
                              onChange={this.handleChange}
                              className="form-control form-control-lg"
                              placeholder="Password"
                            />
                            <div className="text-danger pb-2">
                              {this.validator.message(
                                "password",
                                this.state.data.password,
                                "required|min:8"
                              )}
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="confirmpassword">
                              Confirm Password
                              <span className="text-danger px-1">*</span>
                            </label>
                            <input
                              type="password"
                              id="confirmpassword"
                              onBlur={() =>
                                this.validator.showMessageFor(
                                  "password confirmation"
                                )
                              }
                              name="confirmpassword"
                              value={this.state.data.confirmpassword}
                              onChange={this.handleChange}
                              className="form-control form-control-lg"
                              placeholder="Confirm Password"
                            />
                            <div className="text-danger pb-2">
                              {this.validator.message(
                                "password confirmation",
                                this.state.data.confirmpassword,
                                `required|in:${this.state.data.password}`
                              )}
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">
                              Address<span className="text-danger px-1">*</span>
                            </label>
                            <input
                              type="text"
                              id="address"
                              name="address"
                              value={this.state.data.address}
                              onChange={this.handleChange}
                              onBlur={() =>
                                this.validator.showMessageFor("address")
                              }
                              className="form-control form-control-lg"
                              placeholder="Address"
                            />
                            <div className="text-danger pb-2">
                              {this.validator.message(
                                "address",
                                this.state.data.address,
                                "required"
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="name">
                                  City
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="city"
                                  onBlur={() =>
                                    this.validator.showMessageFor("city")
                                  }
                                  name="city"
                                  value={this.state.data.city}
                                  onChange={this.handleChange}
                                  className="form-control form-control-lg"
                                  placeholder="City"
                                />
                                <div className="text-danger pb-2">
                                  {this.validator.message(
                                    "city",
                                    this.state.data.city,
                                    "required"
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="name">
                                  District
                                  <span className="text-danger px-1">*</span>
                                </label>
                                <select
                                  name="district"
                                  id="district"
                                  onBlur={() =>
                                    this.validator.showMessageFor("district")
                                  }
                                  value={this.state.district}
                                  onChange={this.handleChange}
                                  className="form-control form-control-lg "
                                >
                                  <option
                                    defaultValue="none"
                                    selected
                                    hidden
                                    disabled
                                  ></option>
                                  {Nepal.District().map((items) => {
                                    return (
                                      <option key={items} value={items}>
                                        {items}
                                      </option>
                                    );
                                  })}
                                </select>
                                <div className="text-danger pb-2">
                                  {this.validator.message(
                                    "district",
                                    this.state.data.district,
                                    "required"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">
                              Phone<span className="text-danger px-1">*</span>
                            </label>
                            <input
                              type="number"
                              id="phone"
                              name="phone"
                              onBlur={() =>
                                this.validator.showMessageFor("phone")
                              }
                              onKeyPress={(event) => {
                                return event.charCode >= 48;
                              }}
                              min="0"
                              value={this.state.data.phone}
                              onChange={this.handleChange}
                              className="form-control form-control-lg"
                              placeholder="Phone"
                            />
                            <div className="text-danger pb-2">
                              {this.validator.message(
                                "phone",
                                this.state.data.phone,
                                "required|phone|min:0"
                              )}
                            </div>
                          </div>
                          <div className="checkbox py-3">
                            <input
                              type="checkbox"
                              id="terms"
                              name="terms"
                              onChange={() => {
                                this.setState({
                                  terms: !this.state.terms,
                                });
                              }}
                              value={this.state.terms}
                            />
                            <label htmlFor="terms">
                              By registering,I accept our Terms of Use and
                              Privacy.
                            </label>
                            <div className="text-danger pb-2">
                              {this.validator.message(
                                "terms",
                                this.state.data.terms,
                                this.state.terms ? "boolean" : "required"
                              )}
                            </div>
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary btn-lg"
                            onClick={this.handleSubmit}
                            disabled={this.state.loading}
                          >
                            {this.state.loading && (
                              <i className="fa fa-refresh fa-spin px-2"></i>
                            )}
                            Register
                          </button>
                        </form>
                      </div>
                    </div>
                    <div> </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
export default withRouter(RegisterView);
