import React, { Component, Fragment } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import Dropzone from "react-dropzone";
import Geocode from "react-geocode";
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
Geocode.enableDebug();
import Map from "./Map";
import { scrollToTop } from "../../../..";
class AddListingBody extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      show: false,

      loading: false,
      error: false,
      redirect: false,
      company_title: "",
      company_name: "",
      company_type: "",
      company_price: "",
      price_status: "",
      address: "",
      latitude: "",
      longitude: "",
      city: "",
      tole: "",
      district: "",
      country: "",
      province: "",
      ward_number: "",
      house_number: "",
      zip_code: "",
      number_of_rooms: "",
      salesperday: "",
      rent_of_rooms: "",
      used_for: "",
      tax_status: "",
      description: "",
      reasons_for_selling: "",
      products_worth: "",
      available_furniture: "",
      brand_name: "",
      decoration: "",
      parking: false,
      water: false,
      internet: false,
      bank_nearby: false,
      pitched_road: false,
      airport_nearby: false,
      sewage: false,
      alarm: false,
      cctv: false,
      ac: false,
      categorylist: [],
      documents: [],
      files: [],
      mapPosition: { lat: 27.7172, lng: 85.324 },
      markerPosition: {
        lat: 27.7172,
        lng: 85.324,
      },
      center: {
        lat: 27.7172,
        lng: 85.324,
      },
      locationAdded: false,
      isMapLoading: false,
      errorMessage: "",
      showMap: false,
    };

    this.onDrop = (files) => {
      this.state.files.length < 10 && files.length < 10
        ? this.setState({ files: this.state.files.concat(files) })
        : alert("You cannot upload more than 10 photos");
    };
    this.onFileDrop = (files) => {
      this.state.documents.length < 10 && files.length < 10
        ? this.setState({ documents: this.state.documents.concat(files) })
        : alert("You cannot upload more than 10 files");
    };

    this.arrayRemove = (url) => {
      const newArray = this.state.files.filter((item) => item.path !== url);
      this.setState({ files: newArray });
    };
    this.arrayDocumentRemove = (url) => {
      const newArray = this.state.documents.filter((item) => item.path !== url);
      this.setState({ documents: newArray });
    };
    this.validator = new SimpleReactValidator();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validator1 = new SimpleReactValidator();
  }
  handleEditorChange = (content) => {
    this.setState({ description: content });
  };
  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  updateMap = (data, state) => {
    this.setState({
      district: state.district,
      tole: state.tole,
      address: state.address,
      zip_code: state.zip_code,
      city: state.city,
      province: state.province,
      country: state.country,
      markerPosition: {
        lat: data.lat,
        lng: data.lng,
      },
      mapPosition: {
        lat: data.lat,
        lng: data.lng,
      },
      latitude: data.lat,
      longitude: data.lng,
      showMap: true,
    });
  };
  changeShow = () => {
    this.setState({
      showMap: false,
    });
  };
  onPlaceSelected = (place) => {
    this.setState({
      isMapLoading: true,
    });
    const addressArray = place.address_components,
      city = this.getCity(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    const district = this.getCity(addressArray);
    const tole = this.getTole(addressArray);
    const address_map = this.getAddress(addressArray);

    const zip_code = this.getZipCode(addressArray);
    const country = this.getCountry(addressArray);
    this.setState({
      district: district ? district : "",
      tole: tole ? tole : "",
      address: address_map ? address_map : "",
      zip_code: zip_code ? zip_code : "",
      city: city ? city : "",
      country: country ? country : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
      isMapLoading: false,
    });
  };

  onMarkerDragEnd = (event) => {
    this.setState({
      isMapLoading: true,
    });
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng(),
      addressArray = [];
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          province = this.getState(addressArray);
        const district = this.getCity(addressArray);
        const tole = this.getTole(addressArray);
        const address_map = this.getAddress(addressArray);
        const zip_code = this.getZipCode(addressArray);
        const country = this.getCountry(addressArray);
        this.setState({
          address: address_map ? address_map : "",
          area: area ? area : "",
          city: city ? city : "",
          district: district ? district : "",
          province: province ? province : "",
          zip_code: zip_code ? zip_code : "",
          country: country ? country : "",
          tole: tole ? tole : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
          isMapLoading: false,
        });
      },
      (error) => {
        alert(error);
        this.setState({
          isMapLoading: false,
        });
      }
    );
  };
  getCity = (addressArray) => {
    let city = "";
    if (addressArray) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "locality" === addressArray[i].types[0]
        ) {
          city = addressArray[i].long_name;
          return city;
        }
      }
    }
  };

  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "sublocality" === addressArray[i].types[j] ||
            "political" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  getZipCode = (addressArray) => {
    let postalCode = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ("postal_code" === addressArray[i].types[j]) {
            postalCode = addressArray[i].long_name;
            return postalCode;
          }
        }
      }
    }
  };
  getCountry = (addressArray) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ("country" === addressArray[i].types[j]) {
            country = addressArray[i].long_name;
            return country;
          }
        }
      }
    }
  };
  getTole = (addressArray) => {
    let tole = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ("route" === addressArray[i].types[j]) {
            tole = addressArray[i].long_name;
            return tole;
          }
        }
      }
    }
  };
  getAddress = (addressArray) => {
    let address = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            address = addressArray[i].long_name;
            return address;
          }
        }
      }
    }
  };

  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  componentDidMount() {
    Axios.get(process.env.REACT_APP_API + "companycategory")
      .then((response) => {
        this.setState({
          categorylist: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  handleModalClose = () => {
    if (this.validator1.allValid()) {
      this.handleClose();
      this.setState({
        locationAdded: true,
      });
    } else {
      this.validator1.showMessages();
      this.forceUpdate();
    }
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const data = new FormData();

    this.state.files.forEach((file) => {
      data.append("photos[]", file, file.name);
    });
    this.state.documents.forEach((file) => {
      data.append("document[]", file, file.name);
    });
    if (this.validator.allValid() && this.validator1.allValid()) {
      let submitData = {
        company_title: this.state.company_title,
        company_name: this.state.company_name,
        category_name: this.state.company_type,
        company_price: this.state.company_price,
        price_status: this.state.price_status,
        address: this.state.address,
        latitude: this.state.markerPosition.lat,
        longitude: this.state.markerPosition.lng,
        city: this.state.city,
        tole: this.state.tole,
        district: this.state.district,
        country: this.state.country,
        province: this.state.province,
        ward_number: this.state.ward_number,
        house_number: this.state.house_number,
        zip_code: this.state.zip_code,
        number_of_rooms: this.state.number_of_rooms,
        salesperday: this.state.salesperday,
        rent_of_rooms: this.state.rent_of_rooms,
        used_for: this.state.used_for,
        tax_status: this.state.tax_status,
        description: this.state.description,
        reasons_for_selling: this.state.reasons_for_selling,
        products_worth: this.state.products_worth,
        available_furniture: this.state.available_furniture,
        brand_name: this.state.brand_name,
        decoration: this.state.decoration,
        parking: this.state.parking ? "yes" : "no",
        water: this.state.water ? "yes" : "no",
        internet: this.state.internet ? "yes" : "no",
        bank_nearby: this.state.bank_nearby ? "yes" : "no",
        pitched_road: this.state.pitched_road ? "yes" : "no",
        airport_nearby: this.state.airport_nearby ? "yes" : "no",
        sewage: this.state.sewage ? "yes" : "no",
        alarm: this.state.alarm ? "yes" : "no",
        cctv: this.state.cctv ? "yes" : "no",
        ac: this.state.ac ? "yes" : "no",
      };

      console.log(submitData);
      for (var key in submitData) {
        data.append(key, submitData[key]);
      }
      Axios.post(process.env.REACT_APP_API + "company", data)

        .then((response) => {
          this.props.history.push("/company/list");
        })
        .catch((error) => {
          scrollToTop();
          this.setState({
            error: true,
            loading: false,
          });
        });
    } else {
      this.validator.showMessages();
      this.validator1.showMessages();
      scrollToTop();
      this.forceUpdate();
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const dropzoneStyle = {
      width: "100%",
      height: "20%",
      marginTop: "20px",
      border: "1px solid #D2D2D2",
    };

    return (
      <div className="col-md-7 col-lg-8 col-xl-8">
        <div className="page-header bordered">
          <h1>
            Submit your Company Here{" "}
            <small>We've over 15 Lac buyers and tenants for you!</small>
          </h1>
        </div>

        <form onSubmit={this.handleSubmit} encType="multipart/form-data">
          <h3 className="subheadline">Basic Details</h3>
          <div className="form-group">
            <label htmlFor="company_title">
              Title for Ad<span className="text-danger px-1">*</span>
            </label>
            <input
              type="text"
              name="company_title"
              className="form-control form-control-lg"
              id="company_title"
              onChange={this.handleChange}
              onBlur={() => this.validator.showMessageFor("company_title")}
              value={this.state.company_title}
            />
            {this.validator.message(
              "company_title",
              this.state.company_title,
              "required"
            )}
          </div>

          <div className="form-group">
            <label htmlFor="company_name">
              Company Name<span className="text-danger px-1">*</span>
            </label>
            <input
              type="text"
              name="company_name"
              className="form-control form-control-lg"
              onBlur={() => this.validator.showMessageFor("company_name")}
              id="company_name"
              onChange={this.handleChange}
              value={this.state.company_name}
            />
            {this.validator.message(
              "company_name",
              this.state.company_name,
              "required"
            )}
          </div>

          <div className="form-group">
            <label htmlFor="company_type">
              Company Type<span className="text-danger px-1">*</span>
            </label>
            <select
              name="company_type"
              id="company_type"
              onBlur={() => this.validator.showMessageFor("company_type")}
              onChange={this.handleChange}
              defaultValue={this.state.company_type}
              className="form-control form-control-lg "
            >
              <option defaultValue="none" selected hidden disabled>
                Choose an Category
              </option>
              {this.state.categorylist.map((category) => {
                return (
                  <option key={category.id} value={category.category_name}>
                    {category.category_name}
                  </option>
                );
              })}
            </select>
            {this.validator.message(
              "company_type",
              this.state.company_type,
              "required"
            )}
          </div>

          <div className="form-group">
            <label htmlFor="company_price">
              Company Price<span className="text-danger px-1">*</span>
            </label>
            <input
              type="number"
              name="company_price"
              onBlur={() => this.validator.showMessageFor("company_price")}
              className="form-control form-control-lg"
              id="company_price"
              onChange={this.handleChange}
              value={this.state.company_price}
            />
            {this.validator.message(
              "company_price",
              this.state.company_price,
              "required"
            )}
          </div>

          <div className="form-group">
            <label>
              Is Price Fixed/Negotiable?
              <span className="text-danger px-1">*</span>
            </label>
            <div>
              <div className="radio radio-inline">
                <input
                  type="radio"
                  onChange={this.handleChange}
                  name="price_status"
                  onBlur={() => this.validator.showMessageFor("price_status")}
                  id="fixed"
                  value="fixed"
                />
                <label htmlFor="fixed">Fixed</label>
              </div>
              <div className="radio radio-inline">
                <input
                  type="radio"
                  onChange={this.handleChange}
                  onBlur={() => this.validator.showMessageFor("price_status")}
                  name="price_status"
                  id="negotiable"
                  value="negotiable"
                />
                <label htmlFor="negotiable">Negotiable</label>
              </div>
              {this.validator.message(
                "price_status",
                this.state.price_status,
                "required"
              )}
            </div>
          </div>
          <h3 className="subheadline">Features</h3>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="number_of_rooms">
                  Number of Rooms<span className="text-danger px-1">*</span>
                </label>
                <input
                  type="number"
                  name="number_of_rooms"
                  onBlur={() =>
                    this.validator.showMessageFor("number_of_rooms")
                  }
                  value={this.state.number_of_rooms}
                  onChange={this.handleChange}
                  className="form-control form-control-lg"
                />
                {this.validator.message(
                  "number_of_rooms",
                  this.state.number_of_rooms,
                  "required|numeric|min:1,num"
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Rent of Rooms</label>
                <input
                  type="number"
                  onBlur={() => this.validator.showMessageFor("rent_of_rooms")}
                  value={this.state.rent_of_rooms}
                  onChange={this.handleChange}
                  className="form-control form-control-lg"
                  id="rent_of_rooms"
                  name="rent_of_rooms"
                />
                {this.validator.message(
                  "rent_of_rooms",
                  this.state.rent_of_rooms,
                  "numeric|min:1,num"
                )}
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="tax_status">
                  Tax Status<span className="text-danger px-1">*</span>
                </label>
                <select
                  name="tax_status"
                  id="tax_status"
                  onBlur={() => this.validator.showMessageFor("tax_status")}
                  value={this.state.tax_status}
                  onChange={this.handleChange}
                  className="form-control form-control-lg "
                >
                  {" "}
                  <option selected disabled hidden></option>
                  <option value="cleared">Cleared</option>
                  <option value="notcleared">Remaining</option>
                </select>
                {this.validator.message(
                  "tax_status",
                  this.state.tax_status,
                  "required"
                )}
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label>
                  Products Worth <span className="text-danger px-1">*</span>
                </label>
                <input
                  type="number"
                  onBlur={() => this.validator.showMessageFor("products_worth")}
                  onChange={this.handleChange}
                  value={this.state.products_worth}
                  className="form-control form-control-lg"
                  id="products_worth"
                  name="products_worth"
                />
                {this.validator.message(
                  "products_worth",
                  this.state.products_worth,
                  "required|numeric|min:1,num"
                )}
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label>
                  Sales per day<span className="text-danger px-1">*</span>
                </label>
                <input
                  type="number"
                  onBlur={() => this.validator.showMessageFor("salesperday")}
                  onChange={this.handleChange}
                  value={this.state.salesperday}
                  className="form-control form-control-lg"
                  id="salesperday"
                  name="salesperday"
                />
                {this.validator.message(
                  "salesperday",
                  this.state.salesperday,
                  "required|numeric|min:0,num"
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Brand Name</label>
                <input
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.brand_name}
                  className="form-control form-control-lg"
                  id="brand_name"
                  name="brand_name"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Used for (in yrs)</label>
                <input
                  type="number"
                  onBlur={() => this.validator.showMessageFor("used_for")}
                  onChange={this.handleChange}
                  value={this.state.used_for}
                  className="form-control form-control-lg"
                  id="used_for"
                  name="used_for"
                />
                {this.validator.message(
                  "used_for",
                  this.state.used_for,
                  "numeric|min:1,num"
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  Reason for Selling<span className="text-danger px-1">*</span>
                </label>
                <textarea
                  type="text"
                  onBlur={() =>
                    this.validator.showMessageFor("reasons_for_selling")
                  }
                  value={this.state.reasons_for_selling}
                  onChange={this.handleChange}
                  className="form-control form-control-lg"
                  id="reasons_for_selling"
                  name="reasons_for_selling"
                />
                {this.validator.message(
                  "reasons_for_selling ",
                  this.state.reasons_for_selling,
                  "required"
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Available Furniture</label>
                <textarea
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.available_furniture}
                  className="form-control form-control-lg"
                  id="available_furniture"
                  name="available_furniture"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Available Decoration</label>
                <textarea
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.decoration}
                  className="form-control form-control-lg"
                  id="decoration"
                  name="decoration"
                />
              </div>
            </div>
          </div>
          <h3 className="subheadline">Location</h3>
          <div className="row">
            <div className="col-md-12">
              {this.state.locationAdded && (
                <div>
                  <h6>
                    {" "}
                    <i className="fa fa-check text-success px-2"></i>Location
                    Added Successfully
                  </h6>
                  <a
                    onClick={this.handleShow}
                    className="btn btn-primary mt-3 mb-3"
                  >
                    Change
                  </a>
                  <hr />
                </div>
              )}
              {!this.state.locationAdded && (
                <a
                  className="btn btn-secondary text-light"
                  onClick={this.handleShow}
                >
                  <span>
                    <i className="fa fa-plus px-2" aria-hidden="true" /> Add a
                    location
                  </span>
                </a>
              )}
              <Modal
                size="lg"
                show={this.state.show}
                onHide={this.handleClose}
                keyboard={false}
                onExit={this.changeShow}
              >
                <Modal.Header style={{ marginBottom: "10px" }}>
                  <h6>Please enter your location</h6>
                  <button
                    type="button"
                    className="close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <Map
                        updateMap={this.updateMap}
                        setLocation={this.setLocation}
                        validator1={this.validator1}
                        google={this.props.google}
                        center={{ lat: 27.7172, lng: 85.324 }}
                        height="400px"
                        onPlaceSelected={this.onPlaceSelected}
                        onMarkerDragEnd={this.onMarkerDragEnd}
                        state={this.state}
                        zoom={15}
                      />
                    </div>
                  </div>
                  {this.state.showMap && (
                    <React.Fragment>
                      <hr />

                      <button
                        onClick={this.handleModalClose}
                        className="btn btn-primary btn-lg"
                      >
                        Add your Location
                      </button>
                    </React.Fragment>
                  )}
                </Modal.Body>
              </Modal>
              {!this.state.locationAdded &&
                this.validator1.messageWhenPresent(
                  "The location field must be added."
                )}
            </div>
          </div>

          <br />
          <div className="form-group">
            <h3 className="subheadline">Additional Features</h3>
            <div className="feature-list">
              <div className="checkbox">
                <input
                  type="checkbox"
                  name="parking"
                  id="parking"
                  checked={this.state.parking}
                  onChange={this.handleChange}
                />
                <label htmlFor="parking">Parking</label>
              </div>
            </div>
          </div>

          <div className="checkbox">
            <input
              type="checkbox"
              id="water"
              name="water"
              checked={this.state.water}
              onChange={this.handleChange}
            />
            <label htmlFor="water">Water</label>
          </div>
          <div className="checkbox">
            <input
              type="checkbox"
              id="internet"
              name="internet"
              checked={this.state.internet}
              onChange={this.handleChange}
            />
            <label htmlFor="internet">Internet</label>
          </div>
          <div className="checkbox">
            <input
              type="checkbox"
              id="bank_nearby"
              name="bank_nearby"
              checked={this.state.bank_nearby}
              onChange={this.handleChange}
            />
            <label htmlFor="bank_nearby">Bank Nearby</label>
          </div>
          <div className="checkbox">
            <input
              type="checkbox"
              id="pitched_road"
              name="pitched_road"
              checked={this.state.pitched_road}
              onChange={this.handleChange}
            />
            <label htmlFor="pitched_road">Pitched Road</label>
          </div>
          <div className="checkbox">
            <input
              type="checkbox"
              id="airport_nearby"
              name="airport_nearby"
              checked={this.state.airport_nearby}
              onChange={this.handleChange}
            />
            <label htmlFor="airport_nearby">Airport Nearby</label>
          </div>
          <div className="checkbox">
            <input
              type="checkbox"
              id="sewage"
              name="sewage"
              checked={this.state.sewage}
              onChange={this.handleChange}
            />
            <label htmlFor="sewage">Sewage</label>
          </div>

          <div className="checkbox">
            <input
              type="checkbox"
              id="alarm"
              name="alarm"
              checked={this.state.alarm}
              onChange={this.handleChange}
            />
            <label htmlFor="alarm">Alarm</label>
          </div>
          <div className="checkbox">
            <input
              type="checkbox"
              id="cctv"
              name="cctv"
              checked={this.state.cctv}
              onChange={this.handleChange}
            />
            <label htmlFor="cctv">CCTV Camera</label>
          </div>
          <div className="checkbox">
            <input
              type="checkbox"
              id="ac"
              name="ac"
              checked={this.state.ac}
              onChange={this.handleChange}
            />
            <label htmlFor="ac">Air Conditioning</label>
          </div>
          <div className="form-group">
            <h3 className="subheadline">
              Company Description<span className="text-danger px-1">*</span>
            </h3>

            <Editor
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
              }}
              onEditorChange={this.handleEditorChange}
              onBlur={() => this.validator.showMessageFor("description")}
            />
            {this.validator.message(
              "description",
              this.state.description,
              "required"
            )}
          </div>
          <div className="form-group">
            <h3 className="subheadline">
              Company Documents<span className="text-danger px-1">*</span>
            </h3>
            <small>
              You can upload 10 documents.You have{" "}
              {10 - this.state.documents.length} uploads remaining.
            </small>

            <Dropzone
              onDrop={this.onFileDrop}
              accept="image/png, image/gif, image/jpeg"
              minSize={0}
              maxSize={5242880}
              multiple
            >
              {({ getRootProps, getInputProps }) => (
                <section className="container">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p className="p-4" style={dropzoneStyle}>
                      Click here or drop a file to upload!
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
            <div className="container-fluid">
              <div className="row">
                {this.state.documents.map((item) => {
                  return (
                    <Fragment key={item.path}>
                      <div
                        className=" col-md-2 mx-1 card"
                        style={{ width: "8rem", boxShadow: "none" }}
                      >
                        <img
                          alt="uploaded company"
                          className="card-img-top"
                          src={URL.createObjectURL(item)}
                          style={{ height: "5rem", width: "5rem" }}
                        />
                        <div className="card-body">
                          <p className="card-text">
                            <button
                              className="btn btn-danger mt-2"
                              onClick={() =>
                                this.arrayDocumentRemove(item.path)
                              }
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </p>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
                {this.validator.message(
                  "documents",
                  this.state.documents,
                  "required"
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <h3 className="subheadline">
              Upload Photos<span className="text-danger px-1">*</span>
            </h3>
            <small>
              You can upload 10 photos.You have {10 - this.state.files.length}{" "}
              uploads remaining.
            </small>
            <Dropzone
              onDrop={this.onDrop}
              accept="image/png, image/gif, image/jpeg"
              minSize={0}
              maxSize={5242880}
              multiple
            >
              {({ getRootProps, getInputProps }) => (
                <section className="container">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p className="p-4" style={dropzoneStyle}>
                      Click here or drop a file to upload!
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="container-fluid">
            <div className="row">
              {this.state.files.map((item) => {
                return (
                  <Fragment key={item.path}>
                    <div
                      className=" col-md-2 mx-1 card"
                      style={{ width: "8rem", boxShadow: "none" }}
                    >
                      <img
                        className="card-img-top"
                        src={URL.createObjectURL(item)}
                        style={{ height: "5rem", width: "5rem" }}
                      />
                      <div className="card-body">
                        <p className="card-text">
                          {" "}
                          <button
                            className="btn btn-danger mt-2"
                            onClick={() => this.arrayRemove(item.path)}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>
                        </p>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
              {this.validator.message("photos", this.state.files, "required")}
            </div>
          </div>

          <div className="form-group">
            <button
              type="submit"
              className="btn btn-lg btn-primary mt-3"
              disabled={this.state.loading}
            >
              {this.state.loading && (
                <i className="fa fa-refresh fa-spin px-2"></i>
              )}
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}
export default withRouter(AddListingBody);
