const ContentReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_CONTENT":
      return { ...state, content: actions.payload };

    default:
      return state;
  }
};
export default ContentReducer;
