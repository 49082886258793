import React, { Component, Fragment } from "react";
import Axios from "axios";
import ReactPlayer from "react-player";
import { Spinner } from "../utilities/Spinner";

export default class AboutView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      about: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    Axios.get(process.env.REACT_APP_API + "aboutus")
      .then((response) => {
        console.log(response.data);
        this.setState({
          about: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }
  render() {
    const aboutList = this.state.about.map((about) => {
      return (
        <Fragment>
          <h4 className="title">{about.aboutus_heading}</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: about.aboutus_description,
            }}
          ></div>
          {about.about_video !== null && (
            <ReactPlayer
              url={
                process.env.REACT_APP_BACKEND_CONTENT +
                "aboutus/" +
                about.aboutus_video
              }
              light={
                process.env.REACT_APP_BACKEND_CONTENT +
                "aboutus/thumbnails/" +
                about.thumbnail
              }
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
              }}
              height="400px"
              width="100%"
              controls={true}
            />
          )}
        </Fragment>
      );
    });
    return (
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col col-lg-12 col-xl-8">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href="/about">About</a>
                </li>
              </ol>
            </nav>
            <div className="page-header v2 bordered">
              <h1>
                About Us <small>Know about us</small>
                {this.state.isLoading ? <Spinner /> : aboutList}
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
