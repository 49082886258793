import React, { Component } from "react";
import FacebookLogin from "react-facebook-login";
import Axios from "axios";
import { withRouter } from "react-router";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import cookie from "js-cookie";
class FacebookLoginView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      loading: false,
      redirect: false,
      access_token: "",
      username: "",
    };
    this.responseFacebook = this.responseFacebook.bind(this);
  }
  responseFacebook = (response) => {
    this.setState({
      loading: true,
      access_token: response.accessToken,
      username: response.name,
    });
    const data = {
      access_token: this.state.access_token,
      username: this.state.username,
    };
    Axios.post(process.env.REACT_APP_API + "facebook", data)
      .then((response) => {
        this.setState({
          redirect: true,
          loading: true,
        });
        cookie.set("token", response.data.token.access_token, { path: "/" });
        this.props.setLogin(response.data.username);
        this.props.history.push("/");
        store.addNotification({
          title: "Successful",
          message: "Your have been logged in",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };
  render() {
    return (
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK}
        fields="name,email,picture"
        cssClass="btn btn-social btn-block btn-facebook"
        icon={
          this.state.loading
            ? "fa fa-refresh fa-spin px-2"
            : "icon fa fa-facebook"
        }
        callback={this.responseFacebook}
      />
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) => dispatch({ type: "SET_LOGIN", payload: user }),
  };
};
export default connect(null, mapDispatchToProps)(withRouter(FacebookLoginView));
