import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Footer from "../partials/Footer";
import PasswordResetBody from "./PasswordResetBody";
import queryString from "query-string";
import { withRouter } from "react-router";
class PasswordReset extends Component {
  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    if (
      values.token === undefined ||
      values.token === null ||
      Object.keys(values).length === 0
    ) {
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <Fragment>
        <Navbar />
        <div className="container mt-5 mb-5">
          <PasswordResetBody />
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export default withRouter(PasswordReset);
