import React from "react";
import { Link } from "react-router-dom";

const FeaturedArea = () => {
  return (
    <div className="feature-box centered gray">
      <div>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col col-lg-12 col-xl-12">
              <div className="main-title">
                <span>Find Properties in Major Cities</span>
              </div>
              <div className="main-title-description">
                Thinking abroad? You can now dream and discover international
                properties
              </div>
              <div className="clearfix" />
              <div className="mt50 mb50">
                <div className="featured-gallery v2 item-listing grid">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="item item-lg p-0">
                        <div
                          style={{
                            height: "420px",
                            backgroundImage: "url(img/kathmandu.jpg)",
                            backgroundSize: "cover",
                          }}
                        >
                          <div className="main-item-image-wrapper d-flex justify-content-center align-items-center text-light  flex-column ">
                            KATHMANDU
                            <Link to="/realestate/list?city=Kathmandu">
                              <div className="btn btn-primary btn-sm mt-2 btn-featured-location">
                                View More{" "}
                                <i
                                  className="fa fa-chevron-right pl-2"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="item item-md  p-0">
                            <div
                              style={{
                                height: "200px",
                                backgroundImage: "url(img/lalitpur.jpg)",
                                backgroundSize: "cover",
                              }}
                            >
                              <Link to="/realestate/list?city=Lalitpur">
                                <div className="main-item-image-wrapper d-flex justify-content-center align-items-center text-light flex-column ">
                                  LALITPUR
                                  <div className="btn btn-primary btn-sm mt-2 btn-featured-location">
                                    View More{" "}
                                    <i
                                      className="fa fa-chevron-right pl-2"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </Link>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="item item-md mt-3 p-0">
                            <div
                              style={{
                                height: "200px",
                                backgroundImage: "url(img/bhaktapur.jpg)",
                                backgroundSize: "cover",
                              }}
                            >
                              <Link to="/realestate/list?city=Bhaktapur">
                                <div className="main-item-image-wrapper d-flex justify-content-center align-items-center text-light  flex-column">
                                  BHAKTAPUR
                                  <div className="btn btn-primary btn-sm mt-2  btn-featured-location">
                                    View More{" "}
                                    <i
                                      className="fa fa-chevron-right pl-2"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedArea;
