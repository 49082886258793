import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import Axios from "axios";
import { withRouter } from "react-router";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import cookie from "js-cookie";
class GoogleLoginView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      loading: false,
      redirect: false,
      access_token: "",
      username: "",
    };
    this.responseGoogle = this.responseGoogle.bind(this);
  }
  responseGoogle = (response) => {
    console.log(response.accessToken);
    this.setState({
      loading: true,
      access_token: response.accessToken,
      username: response.profileObj.name,
    });
    const data = {
      access_token: this.state.access_token,
      username: this.state.username,
    };
    Axios.post(process.env.REACT_APP_API + "google", data)
      .then((response) => {
        this.setState({
          loading: false,
          redirect: true,
        });
        console.log(response.data.access_token);
        cookie.set("token", response.data.access_token, { path: "/" });
        this.props.setLogin(response.data.username);
        this.props.history.push("/");
        store.addNotification({
          title: "Successful",
          message: "Your have been logged in",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };
  render() {
    return (
      <div>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className="btn btn-social btn-block btn-google"
            >
              <i
                className={
                  this.state.loading
                    ? "fa fa-refresh fa-spin px-2"
                    : "icon fa fa-google"
                }
              ></i>
              Login with Google
            </button>
          )}
          onSuccess={this.responseGoogle}
          cssClass="loginBtn loginBtn--google"
          onFailure={this.responseGoogle}
        />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) => dispatch({ type: "SET_LOGIN", payload: user }),
  };
};
export default connect(null, mapDispatchToProps)(withRouter(GoogleLoginView));
