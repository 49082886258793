import React, { Component, Fragment } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import Dropzone from "react-dropzone";
import { withRouter } from "react-router";
import { Modal } from "react-bootstrap";
import Geocode from "react-geocode";
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
Geocode.enableDebug();
import Map from "./Map";
import { scrollToTop } from "../../../../index";
import { Spinner } from "../../../utilities/Spinner";
class EditListingBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorylist: [],
      data: [],
      isLoading: false,
      error: false,
      redirect: false,
      property_name: "",
      property_status: "",
      property_price: "",
      price_status: "",
      tole: "",
      address: "",
      city: "",
      district: "",
      country: "",
      province: "",
      ward_number: "",
      house_number: "",
      zip_code: "",
      property_area: "",
      area_type: "",
      mohada_length: "",
      mohada_direction: "",
      built_year: "",
      number_of_kitchens: "",
      number_of_bedrooms: "",
      number_of_bathrooms: "",
      number_of_floors: "",
      description: "",
      gym: false,
      garden: false,
      swimming_pool: false,
      internet: false,
      parking: false,
      water: false,
      school_college_nearby: false,
      shopping_nearby: false,
      bank_nearby: false,
      pitched_road: false,
      airport_nearby: false,
      sewage: false,
      alarm: false,
      cctv: false,
      ac: false,
      files: [],
      newFile: [],
      locationAdded: true,
      isMapLoading: false,
      errorMessage: "",
      showMap: false,
    };
    this.onDrop = (files) => {
      this.state.files.length < 10 &&
      files.length < 10 &&
      this.state.files.length + files.length <= 10
        ? this.setState({
            files: [...files, ...this.state.files],
            newFile: files,
          })
        : alert("You cannot upload more than 10 photos");
    };

    this.validator = new SimpleReactValidator();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.validator1 = new SimpleReactValidator();
    this.arrayRemove = this.arrayRemove.bind(this);
  }
  componentDidMount() {
    this.getCategory();
    this.getData();
  }
  getCategory = () => {
    this.setState({
      isLoading: true,
    });
    Axios.get(process.env.REACT_APP_API + "realestatecategory")
      .then((response) => {
        this.setState({
          isLoading: false,
          categorylist: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  getData = () => {
    this.setState({
      isLoading: true,
    });
    Axios.get(process.env.REACT_APP_API + "realestates/" + this.props.id)
      .then((response) => {
        console.log(response);
        const value = response.data.data;
        this.setState({
          isLoading: false,
          property_name: value.property_name,
          property_status: value.property_status,
          property_price: value.property_price,
          price_status: value.price_status,
          address: value.address,
          city: value.city,
          tole: value.tole,
          category: value.category,
          district: value.district,
          country: value.country,
          province: value.province,
          ward_number: value.ward_number,
          house_number: value.house_number,
          zip_code: value.zip_code,
          property_area: value.property_area,
          area_type: value.area_type,
          mohoda_length: value.mohoda_length,
          mohoda_direction: value.mohoda_direction,
          built_year: value.year_build,
          number_of_kitchens: value.number_of_kitchens,
          number_of_bedrooms: value.number_of_bedrooms,
          number_of_bathrooms: value.number_of_bathrooms,
          number_of_floors: value.number_of_floors,
          description: value.description,
          gym: value.gym === "yes" ? (value.gym = true) : (value.gym = false),
          garden:
            value.garden === "yes"
              ? (value.garden = true)
              : (value.garden = false),
          swimming_pool:
            value.swimming_pool === "yes"
              ? (value.swimming_pool = true)
              : (value.swimming_pool = false),
          internet:
            value.internet === "yes"
              ? (value.internet = true)
              : (value.internet = false),
          parking:
            value.parking === "yes"
              ? (value.parking = true)
              : (value.parking = false),
          water:
            value.water === "yes"
              ? (value.water = true)
              : (value.water = false),
          school_college_nearby:
            value.school_college_nearby === "yes"
              ? (value.school_college_nearby = true)
              : (value.school_college_nearby = false),
          shopping_nearby:
            value.shopping_nearby === "yes"
              ? (value.shopping_nearby = true)
              : (value.shopping_nearby = false),
          bank_nearby:
            value.bank_nearby === "yes"
              ? (value.bank_nearby = true)
              : (value.bank_nearby = false),
          pitched_road:
            value.pitched_road === "yes"
              ? (value.pitched_road = true)
              : (value.pitched_road = false),
          airport_nearby:
            value.airport_nearby === "yes"
              ? (value.airport_nearby = true)
              : (value.airport_nearby = false),
          sewage:
            value.sewage === "yes"
              ? (value.sewage = true)
              : (value.sewage = false),
          alarm:
            value.alarm === "yes"
              ? (value.alarm = true)
              : (value.alarm = false),
          cctv:
            value.cctv === "yes" ? (value.cctv = true) : (value.cctv = false),
          ac: value.ac === "yes" ? (value.ac = true) : (value.ac = false),
          files: value.photos,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  updateMap = (data, state) => {
    this.setState({
      district: state.district,
      tole: state.tole,
      address: state.address,
      zip_code: state.zip_code,
      city: state.city,
      province: state.province,
      country: state.country,
      markerPosition: {
        lat: data.lat,
        lng: data.lng,
      },
      mapPosition: {
        lat: data.lat,
        lng: data.lng,
      },
      latitude: data.lat,
      longitude: data.lng,
      showMap: true,
    });
  };
  changeShow = () => {
    this.setState({
      showMap: false,
    });
  };
  onPlaceSelected = (place) => {
    this.setState({
      isMapLoading: true,
    });
    const addressArray = place.address_components,
      city = this.getCity(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    const district = this.getCity(addressArray);
    const tole = this.getTole(addressArray);
    const address_map = this.getAddress(addressArray);

    const zip_code = this.getZipCode(addressArray);
    const country = this.getCountry(addressArray);
    this.setState({
      district: district ? district : "",
      tole: tole ? tole : "",
      address: address_map ? address_map : "",
      zip_code: zip_code ? zip_code : "",
      city: city ? city : "",
      country: country ? country : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
      isMapLoading: false,
    });
  };

  onMarkerDragEnd = (event) => {
    this.setState({
      isMapLoading: true,
    });
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng(),
      addressArray = [];
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          province = this.getState(addressArray);
        const district = this.getCity(addressArray);
        const tole = this.getTole(addressArray);
        const address_map = this.getAddress(addressArray);
        const zip_code = this.getZipCode(addressArray);
        const country = this.getCountry(addressArray);
        this.setState({
          address: address_map ? address_map : "",
          area: area ? area : "",
          city: city ? city : "",
          district: district ? district : "",
          province: province ? province : "",
          zip_code: zip_code ? zip_code : "",
          country: country ? country : "",
          tole: tole ? tole : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
          isMapLoading: false,
        });
      },
      (error) => {
        alert(error);
        this.setState({
          isMapLoading: false,
        });
      }
    );
  };
  getCity = (addressArray) => {
    let city = "";
    if (addressArray) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "locality" === addressArray[i].types[0]
        ) {
          city = addressArray[i].long_name;
          return city;
        }
      }
    }
  };

  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "sublocality" === addressArray[i].types[j] ||
            "political" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  getZipCode = (addressArray) => {
    let postalCode = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ("postal_code" === addressArray[i].types[j]) {
            postalCode = addressArray[i].long_name;
            return postalCode;
          }
        }
      }
    }
  };
  getCountry = (addressArray) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ("country" === addressArray[i].types[j]) {
            country = addressArray[i].long_name;
            return country;
          }
        }
      }
    }
  };
  getTole = (addressArray) => {
    let tole = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if ("route" === addressArray[i].types[j]) {
            tole = addressArray[i].long_name;
            return tole;
          }
        }
      }
    }
  };
  getAddress = (addressArray) => {
    let address = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            address = addressArray[i].long_name;
            return address;
          }
        }
      }
    }
  };

  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  handleClose = () => {
    this.setState({ show: false });
  };
  handleModalClose = () => {
    if (this.validator1.allValid()) {
      this.handleClose();
      this.setState({
        locationAdded: true,
      });
    } else {
      this.validator1.showMessages();
      this.forceUpdate();
    }
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  arrayRemove = (url) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    const newArray = this.state.files.filter((item) => item.path !== url);
    this.setState({ files: newArray });
  };
  handleEditorChange = (content) => {
    this.setState({ description: content });
  };
  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleDeleteSubmit = (items) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    Axios.get(
      process.env.REACT_APP_API +
        "realestates/" +
        this.props.realestate_id +
        "/photos/" +
        items.id +
        "/delete"
    )
      .then((response) => {
        const newArray = this.state.files.filter(
          (item) => item.id !== items.id
        );
        this.setState({ files: newArray });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const data = new FormData();
    this.state.newFile.forEach((file) => {
      data.append("property_image[]", file, file.name);
    });
    if (this.validator.allValid() && this.validator1.allValid()) {
      let submitData = {
        property_name: this.state.property_name,
        property_status: this.state.property_status,
        property_price: this.state.property_price,
        price_status: this.state.price_status,
        tole: this.state.tole,
        address: this.state.address,
        city: this.state.city,
        district: this.state.district,
        country: this.state.country,
        category_name: this.state.category,
        province: this.state.province,
        ward_number: this.state.ward_number,
        house_number: this.state.house_number,
        built_year: this.state.built_year,
        zip_code: this.state.zip_code,
        area_type: this.state.area_type,
        mohoda_length: this.state.mohoda_length,
        mohoda_direction: this.state.mohoda_direction,
        property_area: this.state.property_area,
        number_of_kitchen: this.state.number_of_kitchens,
        number_of_bedrooms: this.state.number_of_bedrooms,
        number_of_bathrooms: this.state.number_of_bathrooms,
        number_of_floors: this.state.number_of_floors,
        description: this.state.description,
        gym: this.state.gym ? "yes" : "no",
        garden: this.state.garden ? "yes" : "no",
        swimming_pool: this.state.swimming_pool ? "yes" : "no",
        internet: this.state.internet ? "yes" : "no",
        parking: this.state.parking ? "yes" : "no",
        water: this.state.water ? "yes" : "no",
        school_college_nearby: this.state.school_college_nearby ? "yes" : "no",
        shopping_nearby: this.state.shopping_nearby ? "yes" : "no",
        bank_nearby: this.state.bank_nearby ? "yes" : "no",
        pitched_road: this.state.pitched_road ? "yes" : "no",
        airport_nearby: this.state.airport_nearby ? "yes" : "no",
        sewage: this.state.sewage ? "yes" : "no",
        alarm: this.state.alarm ? "yes" : "no",
        cctv: this.state.cctv ? "yes" : "no",
        ac: this.state.ac ? "yes" : "no",
      };

      console.log(submitData);
      for (var key in submitData) {
        data.append(key, submitData[key]);
      }

      Axios.post(
        process.env.REACT_APP_API +
          "realestates/" +
          this.props.id +
          "/edit/update",
        data
      )
        .then((response) => {
          this.props.history.push("/user/my-listing");
        })
        .catch((error) => {
          scrollToTop();
          this.setState({
            error: true,
            loading: false,
          });
        });
    } else {
      this.validator.showMessages();
      this.validator1.showMessages();
      this.forceUpdate();
      scrollToTop();
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const dropzoneStyle = {
      width: "100%",
      height: "20%",
      border: "1px solid #D2D2D2",
    };

    return (
      <div className="col-md-7 col-lg-8 col-xl-8">
        <div className="page-header bordered">
          <h1>Edit your Listing</h1>
        </div>

        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <form
            method="POST"
            onSubmit={this.handleSubmit}
            encType="multipart/form-data"
            autoComplete="off"
          >
            <h3 className="subheadline">Basic Details</h3>

            <div className="form-group">
              <label htmlFor="property_name">
                Property Title<span className="text-danger px-1">*</span>
              </label>
              <input
                type="text"
                name="property_name"
                className="form-control form-control-lg"
                id="property_name"
                onChange={this.handleChange}
                onBlur={() => this.validator.showMessageFor("property title")}
                defaultValue={this.state.property_name}
              />
              {this.validator.message(
                "property title",
                this.state.property_name,
                "required"
              )}
            </div>

            <div className="form-group">
              <label>
                For Sale/Rent?<span className="text-danger px-1">*</span>
              </label>
              <div>
                <div className="radio radio-inline">
                  <input
                    type="radio"
                    checked={this.state.property_status === "Rent" ? true : ""}
                    name="property_status"
                    id="rent"
                    onChange={this.handleChange}
                    defaultValue="Rent"
                  />
                  <label htmlFor="rent">Rent</label>
                </div>
                <div className="radio radio-inline">
                  <input
                    type="radio"
                    name="property_status"
                    onChange={this.handleChange}
                    checked={this.state.property_status === "Sell" ? true : ""}
                    onBlur={() =>
                      this.validator.showMessageFor("property_status")
                    }
                    id="sell"
                    defaultValue="Sell"
                  />
                  <label htmlFor="sell">Sell</label>
                </div>
              </div>
              {this.validator.message(
                "property_status",
                this.state.property_status,
                "required"
              )}
            </div>
            <div className="form-group">
              <label htmlFor="category">
                Category<span className="text-danger px-1">*</span>
              </label>
              <select
                name="category"
                id="category"
                onBlur={() => this.validator.showMessageFor("category")}
                defaultValue={this.state.category}
                onChange={this.handleChange}
                className="form-control form-control-lg "
              >
                {this.state.categorylist.map((category) => {
                  return (
                    <option key={category.id} value={category.category_name}>
                      {category.category_name}
                    </option>
                  );
                })}
              </select>
              {this.validator.message(
                "category",
                this.state.category,
                "required"
              )}
            </div>

            <div className="form-group">
              <label>
                Price<span className="text-danger px-1">*</span>
              </label>
              <input
                type="number"
                placeholder="Price in Rs."
                name="property_price"
                onBlur={() => this.validator.showMessageFor("property_price")}
                defaultValue={this.state.property_price}
                onChange={this.handleChange}
                className="form-control form-control-lg"
              />

              {this.validator.message(
                "property_price",
                this.state.property_price,
                "required|numeric|min:1,num"
              )}
            </div>

            <div className="form-group">
              <label>
                Is Price Fixed/Negotiable?
                <span className="text-danger px-1">*</span>
              </label>
              <div>
                <div className="radio radio-inline">
                  <input
                    type="radio"
                    onChange={this.handleChange}
                    name="price_status"
                    checked={this.state.price_status === "Fixed" ? true : ""}
                    onBlur={() => this.validator.showMessageFor("price_status")}
                    id="fixed"
                    defaultValue="Fixed"
                  />
                  <label htmlFor="fixed">Fixed</label>
                </div>
                <div className="radio radio-inline">
                  <input
                    type="radio"
                    onChange={this.handleChange}
                    name="price_status"
                    checked={
                      this.state.price_status === "Negotiable" ? true : ""
                    }
                    id="negotiable"
                    defaultValue="Negotiable"
                  />
                  <label htmlFor="negotiable">Negotiable</label>
                </div>
                {this.validator.message(
                  "price_status",
                  this.state.price_status,
                  "required"
                )}
              </div>
            </div>

            <h3 className="subheadline">Property Description</h3>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Property Area<span className="text-danger px-1">*</span>
                  </label>
                  <input
                    type="number"
                    defaultValue={this.state.property_area}
                    onChange={this.handleChange}
                    className="form-control form-control-lg"
                    id="property_area"
                    onBlur={() =>
                      this.validator.showMessageFor("property_area")
                    }
                    placeholder="Area in Aana/Dhur"
                    name="property_area"
                  />
                  {this.validator.message(
                    "property_area",
                    this.state.property_area,
                    "required|numeric|min:1,num"
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="area_type">
                    Area Type<span className="text-danger px-1">*</span>
                  </label>
                  <select
                    name="area_type"
                    defaultValue={this.state.area_type}
                    onChange={this.handleChange}
                    onBlur={() => this.validator.showMessageFor("area_type")}
                    className="form-control form-control-lg"
                  >
                    <option value="Aana">Aana</option>
                    <option value="Dhur">Dhur</option>
                  </select>
                  {this.validator.message(
                    "area_type",
                    this.state.area_type,
                    "required"
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Mohada Length<span className="text-danger px-1">*</span>
                  </label>
                  <input
                    type="number"
                    defaultValue={this.state.mohoda_length}
                    onChange={this.handleChange}
                    onBlur={() =>
                      this.validator.showMessageFor("mohada_length")
                    }
                    className="form-control form-control-lg"
                    id="mohoda_length"
                    name="mohoda_length"
                  />
                  {this.validator.message(
                    "mohoda_length",
                    this.state.mohoda_length,
                    "required|numeric|min:1,num"
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="mohoda_direction">
                    Mohoda Direction<span className="text-danger px-1">*</span>
                  </label>
                  <select
                    name="mohoda_direction"
                    defaultValue={this.state.mohoda_direction}
                    onBlur={() =>
                      this.validator.showMessageFor("mohada_direction")
                    }
                    onChange={this.handleChange}
                    className="form-control form-control-lg"
                  >
                    {" "}
                    <option value="East">East</option>
                    <option value="West">West</option>
                    <option value="North">North</option>
                    <option value="South">South</option>
                  </select>
                  {this.validator.message(
                    "mohoda_direction",
                    this.state.mohoda_direction,
                    "required"
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Year Built<span className="text-danger px-1">*</span>
                  </label>
                  <input
                    type="date"
                    defaultValue={this.state.built_year}
                    onChange={this.handleChange}
                    className="form-control form-control-lg"
                    id="built_year"
                    onBlur={() => this.validator.showMessageFor("built_year")}
                    name="built_year"
                  />
                  {this.validator.message(
                    "built_year",
                    this.state.built_year,
                    "required"
                  )}
                </div>
              </div>{" "}
            </div>
            <h3 className="subheadline">
              Location <span className="text-danger px-1">*</span>
            </h3>
            <div className="row">
              <div className="col-md-12">
                {this.state.locationAdded && (
                  <div>
                    <h6>
                      {" "}
                      <i className="fa fa-check text-success px-2"></i>Location
                      Added Successfully
                    </h6>
                    <a
                      onClick={this.handleShow}
                      className="btn btn-primary mt-3 mb-3"
                    >
                      Change
                    </a>
                    <hr />
                  </div>
                )}
                {!this.state.locationAdded && (
                  <a
                    className="btn btn-secondary text-light"
                    onClick={this.handleShow}
                  >
                    <span>
                      <i className="fa fa-plus px-2" aria-hidden="true" /> Add a
                      location
                    </span>
                  </a>
                )}
                <Modal
                  size="lg"
                  show={this.state.show}
                  onHide={this.handleClose}
                  keyboard={false}
                  onExit={this.changeShow}
                >
                  <Modal.Header style={{ marginBottom: "10px" }}>
                    <h6>Please enter your location</h6>
                    <button
                      type="button"
                      className="close"
                      onClick={this.handleClose}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="container-fluid">
                      <div className="row justify-content-center">
                        <Map
                          updateMap={this.updateMap}
                          setLocation={this.setLocation}
                          validator1={this.validator1}
                          google={this.props.google}
                          center={{ lat: 27.7172, lng: 85.324 }}
                          height="400px"
                          onPlaceSelected={this.onPlaceSelected}
                          onMarkerDragEnd={this.onMarkerDragEnd}
                          state={this.state}
                          zoom={15}
                        />
                      </div>
                    </div>
                    {this.state.showMap && (
                      <React.Fragment>
                        <hr />

                        <button
                          onClick={this.handleModalClose}
                          className="btn btn-primary btn-lg"
                        >
                          Add your Location
                        </button>
                      </React.Fragment>
                    )}
                  </Modal.Body>
                </Modal>
                {!this.state.locationAdded &&
                  this.validator1.messageWhenPresent(
                    "The location field must be added."
                  )}
              </div>
            </div>

            <h3 className="subheadline">Features</h3>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="bedrooms">
                    Bedrooms<span className="text-danger px-1">*</span>
                  </label>
                  <input
                    type="number"
                    name="number_of_bedrooms"
                    onBlur={() =>
                      this.validator.showMessageFor("number of bedrooms")
                    }
                    onChange={this.handleChange}
                    defaultValue={this.state.number_of_bedrooms}
                    className="form-control form-control-lg"
                  />
                  {this.validator.message(
                    "number of bedrooms",
                    this.state.number_of_bedrooms,
                    "required|numeric|min:0,num"
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="number_of_bathrooms">
                    Bathrooms<span className="text-danger px-1">*</span>
                  </label>
                  <input
                    type="number"
                    name="number_of_bathrooms"
                    onBlur={() =>
                      this.validator.showMessageFor("number of bathrooms")
                    }
                    id="number_of_bathrooms"
                    onChange={this.handleChange}
                    defaultValue={this.state.number_of_bathrooms}
                    className="form-control form-control-lg "
                  />
                  {this.validator.message(
                    "number of bathrooms",
                    this.state.number_of_bathrooms,
                    "required|numeric|min:0,num"
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="bathrooms">
                    Floors<span className="text-danger px-1">*</span>
                  </label>
                  <input
                    type="number"
                    name="number_of_floors"
                    id="number_of_floors"
                    onBlur={() =>
                      this.validator.showMessageFor("number of floors")
                    }
                    defaultValue={this.state.number_of_floors}
                    onChange={this.handleChange}
                    className="form-control form-control-lg "
                  />
                  {this.validator.message(
                    "number of floors",
                    this.state.number_of_floors,
                    "required|numeric|min:0,num"
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="number_of_kitchen">
                    Kitchen<span className="text-danger px-1">*</span>
                  </label>
                  <input
                    type="number"
                    id="number"
                    name="number_of_kitchen"
                    onBlur={() =>
                      this.validator.showMessageFor("number_of_kitchen")
                    }
                    id="number_of_kitchen"
                    defaultValue={this.state.number_of_kitchens}
                    onChange={this.handleChange}
                    className="form-control form-control-lg "
                  />
                  {this.validator.message(
                    "number of kitchen",
                    this.state.number_of_kitchens,
                    "required|numeric|min:0,num"
                  )}
                </div>
              </div>
            </div>

            <br />
            <div className="form-group">
              <h3 className="subheadline">Additional Features</h3>
              <div className="feature-list">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    name="garden"
                    id="garden"
                    checked={this.state.garden}
                    onChange={this.handleChange}
                  />
                  <label htmlFor="garden">Garden</label>
                </div>
              </div>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                name="gym"
                id="gym"
                checked={this.state.gym}
                onChange={this.handleChange}
              />
              <label htmlFor="gym">Gym</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="internet"
                name="internet"
                checked={this.state.internet}
                onChange={this.handleChange}
              />
              <label htmlFor="internet">Internet</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="swimming_pool"
                name="swimming_pool"
                checked={this.state.swimming_pool}
                onChange={this.handleChange}
              />
              <label htmlFor="swimming_pool">Swimming Pool</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="water"
                name="water"
                checked={this.state.water}
                onChange={this.handleChange}
              />
              <label htmlFor="water">Water</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="parking"
                name="parking"
                checked={this.state.parking}
                onChange={this.handleChange}
              />
              <label htmlFor="parking">Parking</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="school_college_nearby"
                name="school_college_nearby"
                checked={this.state.school_college_nearby}
                onChange={this.handleChange}
              />
              <label htmlFor="school_college_nearby">
                School/College Nearby
              </label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="shopping_nearby"
                name="shopping_nearby"
                checked={this.state.shopping_nearby}
                onChange={this.handleChange}
              />
              <label htmlFor="shopping_nearby">Shopping/GroceryNearby</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="bank_nearby"
                name="bank_nearby"
                checked={this.state.bank_nearby}
                onChange={this.handleChange}
              />
              <label htmlFor="bank_nearby">Bank</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="pitched_road"
                name="pitched_road"
                checked={this.state.pitched_road}
                onChange={this.handleChange}
              />
              <label htmlFor="pitched_road">Pitched Road</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="airport_nearby"
                name="airport_nearby"
                checked={this.state.airport_nearby}
                onChange={this.handleChange}
              />
              <label htmlFor="airport_nearby">Airport</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="sewage"
                name="sewage"
                checked={this.state.sewage}
                onChange={this.handleChange}
              />
              <label htmlFor="sewage">Sewage</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="alarm"
                name="alarm"
                checked={this.state.alarm}
                onChange={this.handleChange}
              />
              <label htmlFor="alarm">Alarm</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="cctv"
                name="cctv"
                checked={this.state.cctv}
                onChange={this.handleChange}
              />
              <label htmlFor="cctv">CCTV Camera</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                id="ac"
                name="ac"
                checked={this.state.ac}
                onChange={this.handleChange}
              />
              <label htmlFor="ac">Air Conditioning</label>
            </div>
            <div className="form-group">
              <h3 className="subheadline">
                Property Description<span className="text-danger px-1">*</span>
              </h3>
              <Editor
                initialValue={this.state.description}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={this.handleEditorChange}
              />
            </div>
            <div className="form-group">
              <h3 className="subheadline">Upload Photos</h3>
              <small>
                You can upload 10 photos.You have {10 - this.state.files.length}{" "}
                uploads remaining.
              </small>
              <br />

              <Dropzone
                onDrop={this.onDrop}
                accept="image/*"
                minSize={0}
                maxSize={5242880}
                multiple
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="container">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <p className="p-4" style={dropzoneStyle}>
                        Click here or drop a file to upload!
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="container-fluid">
              <div className="row">
                {console.log(this.state.files)}
                {this.state.files.map((item) => {
                  return (
                    <Fragment key={item.id}>
                      <div
                        className=" col-md-2 mx-1 card"
                        style={{ width: "8rem", boxShadow: "none" }}
                      >
                        {typeof item.photos === "string" ? (
                          <img
                            alt="edit"
                            className="card-img-top"
                            src={
                              process.env.REACT_APP_BACKEND_CONTENT +
                              "images/" +
                              item.photos
                            }
                            style={{ height: "5rem", width: "5rem" }}
                          />
                        ) : (
                          <img
                            alt="edit"
                            className="card-img-top"
                            src={URL.createObjectURL(item)}
                            style={{ height: "5rem", width: "5rem" }}
                          />
                        )}

                        <div className="card-body">
                          <p className="card-text">
                            {typeof item.photos === "string" ? (
                              <button
                                type="submit"
                                className="btn btn-danger mt-2"
                                onClick={this.handleDeleteSubmit(item)}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            ) : (
                              <button
                                className="btn btn-danger mt-2"
                                onClick={this.arrayRemove(item.path)}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            )}
                          </p>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-lg btn-primary mt-3"
                disabled={this.state.loading}
                onClick={this.handleSubmit}
              >
                {this.state.loading && (
                  <i className="fa fa-refresh fa-spin px-2"></i>
                )}
                Submit
              </button>
            </div>
            {this.state.error ? (
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                Some Error Occoured! Please Try Again
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            ) : (
              ""
            )}
          </form>
        )}
      </div>
    );
  }
}
export default withRouter(EditListingBody);
