import React, { Component } from "react"
import Navbar from "../partials/Navbar"
import HomeSearch from "./HomeSearch"
import Featured from "./Featured"
import FeaturedCenter from "./FeaturedCenter"
import Axios from "axios"
import AllFeatured from "./AllFeatured"
import Footer from "../partials/Footer"
import FeaturedVechicle from "../Home/FeaturedVehicle"
import FeaturedCompany from "../Home/FeaturedCompany"
import FeaturedServices from "../Home/FeaturedServices"
import { SuccessToast } from "../utilities/SuccessToast"
import { MainSpinner } from "../utilities/MainSpinner"
import { Helmet } from "react-helmet"
import Connect from "./Connect"
import "react-toastify/dist/ReactToastify.min.css"
import FeaturedArea from "./FeaturedArea"
import FeaturedAds from "./FeaturedAds"
import AllDistricts from "./AllDistricts"
class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allfeatured: [],
      realestatefeatured: [],
      vehiclefeatured: [],
      companyfeatured: [],
      servicefeatured: [],
      banner: [],
      seo: [],
      ads: [],
      districts: [],
      isLoading: true,
    }
  }
  componentDidMount() {
    window.lib()
    window.lib2()
    this.getSEO()
    this.getAllFeatured()
    this.getFeatured()
    this.getVehicleFeatured()
    this.getCompanyFeatured()
    this.getServiceFeatured()
    this.getBanner()
    this.getAds()
    this.getAllDistricts()
  }
  getAllDistricts = () => {
    this.setState({
      isLoading: true,
    })
    Axios.get(process.env.REACT_APP_API + "districts")
      .then((response) => {
        this.setState({
          districts: response.data.data,
          isLoading: false,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        })
      })
  }
  getAds = () => {
    this.setState({
      isLoading: true,
    })
    Axios.get(process.env.REACT_APP_API + "advertisement")
      .then((response) => {
        this.setState({
          ads: response.data.data,
          isLoading: false,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        })
      })
  }
  getSEO = () => {
    Axios.get(process.env.REACT_APP_API + "seo")
      .then((response) => {
        this.setState({
          seo: response.data.data,
          isLoading: false,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        })
      })
  }
  getBanner = () => {
    this.setState({
      isLoading: true,
    })
    Axios.get(process.env.REACT_APP_API + "banner")
      .then((response) => {
        this.setState({
          banner: response.data.data,
          isLoading: false,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        })
      })
  }
  getAllFeatured = () => {
    this.setState({
      isLoading: true,
    })
    Axios.get(process.env.REACT_APP_API + "featured")
      .then((response) => {
        this.setState({
          allfeatured: response.data,
          isLoading: false,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        })
      })
  }
  getFeatured = () => {
    this.setState({
      isLoading: true,
    })
    Axios.get(process.env.REACT_APP_API + "realestates/featured")
      .then((response) => {
        this.setState({
          realestatefeatured: response.data,
          isLoading: false,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        })
      })
  }
  getVehicleFeatured = () => {
    this.setState({
      isLoading: true,
    })
    Axios.get(process.env.REACT_APP_API + "vehicles/featured")
      .then((response) => {
        this.setState({
          vehiclefeatured: response.data,
          isLoading: false,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        })
      })
  }
  getCompanyFeatured = () => {
    this.setState({
      isLoading: true,
    })
    Axios.get(process.env.REACT_APP_API + "company/featured")
      .then((response) => {
        this.setState({
          companyfeatured: response.data,
          isLoading: false,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        })
      })
  }
  getServiceFeatured = () => {
    this.setState({
      isLoading: true,
    })
    Axios.get(process.env.REACT_APP_API + "service/featured")
      .then((response) => {
        this.setState({
          servicefeatured: response.data,
          isLoading: false,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        })
      })
  }
  render() {
    return (
      <div id="main">
        {this.state.isLoading ? (
          <MainSpinner />
        ) : (
          <React.Fragment>
            <Helmet>
              <title>Home</title>
              {this.state.seo.map((item, key) => {
                if (item.page === "homepage") {
                  return <title key={key}>{item.seo_title}</title>
                }
              })}
              {this.state.seo.map((item, key) => {
                if (item.page === "homepage") {
                  return (
                    <meta
                      key={key}
                      name="description"
                      content={item.meta_description}
                    />
                  )
                }
              })}
              {this.state.seo.map((item, key) => {
                if (item.page === "homepage") {
                  return (
                    <meta
                      key={key}
                      name="keywords"
                      content={item.meta_keywords}
                    />
                  )
                }
              })}
            </Helmet>
            <Navbar />
            {SuccessToast()}
            <HomeSearch data={this.state.banner} />
            <div id="content" className="pt0 pb0">
              <AllFeatured data={this.state.allfeatured} />

              {this.state.ads &&
                this.state.ads.some((ad) => ad.priority == 1) && (
                  <FeaturedAds
                    image={
                      this.state.ads.length &&
                      this.state.ads.find((ad) => ad.priority == 1)
                    }
                  />
                )}
              <FeaturedCenter />
              {this.state.ads &&
                this.state.ads.some((ad) => ad.priority == 2) && (
                  <FeaturedAds
                    image={
                      this.state.ads.length &&
                      this.state.ads.find((ad) => ad.priority == 2)
                    }
                  />
                )}
              <FeaturedArea />
              {this.state.ads &&
                this.state.ads.some((ad) => ad.priority == 3) && (
                  <FeaturedAds
                    image={
                      this.state.ads.length &&
                      this.state.ads.find((ad) => ad.priority == 3)
                    }
                  />
                )}
              <Featured data={this.state.realestatefeatured} />
              {this.state.ads &&
                this.state.ads.some((ad) => ad.priority == 3) && (
                  <FeaturedAds
                    image={
                      this.state.ads.length &&
                      this.state.ads.find((ad) => ad.priority == 3)
                    }
                  />
                )}
              <FeaturedVechicle data={this.state.vehiclefeatured} />
              {this.state.ads &&
                this.state.ads.some((ad) => ad.priority == 4) && (
                  <FeaturedAds
                    image={
                      this.state.ads.length &&
                      this.state.ads.find((ad) => ad.priority == 4)
                    }
                  />
                )}
              <Connect />
              <FeaturedCompany data={this.state.companyfeatured} />
              {this.state.ads &&
                this.state.ads.some((ad) => ad.priority == 5) && (
                  <FeaturedAds
                    image={
                      this.state.ads.length &&
                      this.state.ads.find((ad) => ad.priority == 5)
                    }
                  />
                )}
              <FeaturedServices data={this.state.servicefeatured} />
              {this.state.ads &&
                this.state.ads.some((ad) => ad.priority == 5) && (
                  <FeaturedAds
                    image={
                      this.state.ads.length &&
                      this.state.ads.find((ad) => ad.priority == 5)
                    }
                  />
                )}
              <AllDistricts districts={this.state.districts} />

              <Footer />
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}
export default Home
