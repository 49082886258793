import React, { Component } from "react";
import Navbar from "../partials/Navbar";
import Footer from "../partials/Footer";
import NewsBody from "./NewsBody";
import { withRouter } from "react-router-dom";

class News extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <NewsBody />
        <Footer />
      </div>
    );
  }
}
export default withRouter(News);
