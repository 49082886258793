import React, { Component } from "react";
import Axios from "axios";
import { Spinner } from "../utilities/Spinner";
import { withRouter } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import queryString from "query-string";

class PasswordResetBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      email: "",
      passwordConfirm: "",
      isLoading: false,
      isSubmitting: false,
      isUploading: false,
      token: "",
    };
    this.validator = new SimpleReactValidator();
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      isSubmitting: true,
    });
    if (this.state.password === this.state.passwordConfirm) {
      this.updatePassword();
    } else {
      alert("Your password doesn't match");
    }
  };
  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.setState({
      token: values.token,
    });
  }
  updatePassword = () => {
    this.setState({
      loading: true,
    });
    if (this.validator.allValid()) {
      const data = {
        password: this.state.password,
        password_confirmation: this.state.passwordConfirm,
        token: this.state.token,
        email: this.state.email,
      };
      Axios.post(process.env.REACT_APP_API + "password/reset", data)
        .then((response) => {
          this.setState({
            loading: false,
          });
          this.props.history.push("/login");
          store.addNotification({
            title: "Successful!",
            message: "Your password has been changed.Please log in",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({
        loading: false,
      });
    }
  };
  render() {
    return this.state.isLoading ? (
      <div className="col-md-7 col-lg-8 col-xl-8">
        <Spinner />
      </div>
    ) : (
      <div className="col-md-7 col-lg-8 col-xl-8 my-5">
        <div className="page-header bordered">
          <h1>Reset your Password</h1>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              className="form-control form-control-lg"
              defaultValue={this.state.email}
              onChange={this.handleChange}
              placeholder="Your Email"
            />
          </div>
          <div className="text-danger my-2">
            {this.validator.message("email", this.state.email, "required")}
          </div>
          <div className="form-group">
            <label>Your new password</label>
            <input
              type="password"
              name="password"
              className="form-control form-control-lg"
              defaultValue={this.state.password}
              onChange={this.handleChange}
              placeholder="Your new password"
            />
          </div>
          <div className="text-danger my-2">
            {this.validator.message(
              "password",
              this.state.password,
              "required"
            )}
          </div>
          <div className="form-group">
            <label>Repeat new password</label>
            <input
              type="password"
              name="passwordConfirm"
              defaultValue={this.state.passwordConfirm}
              onChange={this.handleChange}
              className="form-control form-control-lg"
              placeholder="Repeat new password"
            />
          </div>
          <div className="text-danger my-2">
            {this.validator.message(
              "passwordConfirm",
              this.state.passwordConfirm,
              "required"
            )}
          </div>
          <hr />
          <div className="form-group action">
            <button type="submit" className="btn btn-lg btn-primary mb-5">
              Change Password
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(PasswordResetBody);
