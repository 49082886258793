import React, { Component } from "react";
import Axios from "axios";
import { Spinner } from "../../utilities/Spinner";
import { withRouter } from "react-router";
import { store } from "react-notifications-component";

class EditProfileBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      address: "",
      phone: "",
      photo: "",
      email: "",
      isLoading: false,
      isSubmitting: false,
    };
  }
  componentDidMount() {
    this.getProfileData();
  }
  getProfileData = () => {
    this.setState({ isLoading: true });
    Axios.get(process.env.REACT_APP_API + "userprofile")
      .then((response) => {
        const profile = response.data.data;
        console.log(profile);
        this.setState({
          firstname: profile.firstname,
          lastname: profile.lastname,
          address: profile.tole,
          city: profile.city,
          district: profile.district,
          phone: profile.phone,
          photo: profile.photo,
          email: profile.email,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.log(error.response);
      });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      isSubmitting: true,
    });
    this.updateProfile();
  };
  updateProfile = () => {
    Axios.post(process.env.REACT_APP_API + "userprofile", {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      photo: this.state.photo,
      tole: this.state.address,
      district: this.state.district,
      city: this.state.city,

      email: this.state.email,
      phone: this.state.phone,
    })
      .then((response) => {
        store.addNotification({
          title: "Successful",
          message: "Your profile  was uploaded successfully",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });
        this.props.history.push("/user/profile");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    return this.state.isLoading ? (
      <div className="col-md-7 col-lg-8 col-xl-8">
        <Spinner />
      </div>
    ) : (
      <div className="col-md-7 col-lg-8 col-xl-8">
        <div className="page-header bordered">
          <h1>Edit Your Profile</h1>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  name="firstname"
                  className="form-control form-control-lg"
                  onChange={this.handleChange}
                  defaultValue={this.state.firstname}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  className="form-control form-control-lg"
                  onChange={this.handleChange}
                  defaultValue={this.state.lastname}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="text"
              name="email"
              className="form-control form-control-lg"
              onChange={this.handleChange}
              defaultValue={this.state.email}
            />
          </div>

          <div className="form-group">
            <label>Phone</label>
            <input
              type="text"
              name="phone"
              className="form-control form-control-lg"
              onChange={this.handleChange}
              defaultValue={this.state.phone}
            />
          </div>

          <div className="form-group">
            <label>Address</label>
            <input
              name="address"
              type="text"
              className="form-control form-control-lg"
              onChange={this.handleChange}
              defaultValue={this.state.address}
            />
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>City</label>
                <input
                  name="city"
                  type="text"
                  className="form-control form-control-lg"
                  onChange={this.handleChange}
                  defaultValue={this.state.city}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>District</label>
                <input
                  name="district"
                  type="text"
                  className="form-control form-control-lg"
                  onChange={this.handleChange}
                  defaultValue={this.state.district}
                />
              </div>
            </div>
          </div>

          <div className="form-group ">
            <button type="submit" className="btn btn-lg btn-primary">
              {this.state.isSubmitting && (
                <i className="fa  fa-circle-o-notch fa-spin px-2"></i>
              )}
              Update Profile
            </button>
          </div>
        </form>
      </div>
    );
  }
}
export default withRouter(EditProfileBody);
