import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Spinner } from "../utilities/Spinner";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    window.toggle();
    window.sidebarDefault();
    this.getNews();
  }
  getNews = () => {
    this.setState({ isLoading: true });
    Axios.get(process.env.REACT_APP_API + "allevents")
      .then((response) => {
        console.log(response);
        this.setState({
          isLoading: false,
          news: response.data.data,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        console.log(error);
      });
  };

  render() {
    return (
      <div id="sidebar" className="sidebar-right">
        <div className="sidebar_inner">
          {/* <div className="input-group input-group-lg mt-4">
            <input type="text" className="form-control" placeholder="Search" />
            <span className="input-group-btn">
              <button className="btn btn-white btn-lg" type="button">
                <i className="fa fa-search" aria-hidden="true" />
              </button>
            </span>
          </div> */}
          <h3 className="subheadline">Recent Events</h3>
          {this.state.isLoading ? (
            <Spinner />
          ) : (
            <div className="list-group no-border">
              {this.state.news.map((item) => {
                return (
                  <Link
                    to={"/events/" + item.id}
                    className="list-group-item news-sidebar "
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
