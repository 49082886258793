import React from "react";
import spinner from "./min.gif";

export const MainSpinner = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center flex-column "
      style={{ height: "100vh", width: "100%" }}
    >
      <img
        src={spinner}
        style={{ width: "150px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    </div>
  );
};
