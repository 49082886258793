import React, { Component } from "react";
import Navbar from "../../partials/Navbar";
import Footer from "../../partials/Footer";
import Sidebar from "../partials/Sidebar";
import EditProfileBody from "./EditProfileBody";

export default class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: "",
    };
  }
  render() {
    return (
      <div>
        <Navbar />
        <div className="clearfix"></div>
        <div id="content">
          <div className="container-fluid col-md-11">
            <div className="row justify-content-md-center">
              <div className="col col-lg-12 col-xl-10">
                <div className="row has-sidebar">
                  <Sidebar />
                  <EditProfileBody
                    profile={this.state.profile}
                    isLoading={this.state.isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
          >
          <Footer />
        </div>
      </div>
    );
  }
}
