import React, { Component } from "react";
import { scrollToTop } from "../../index";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
class Footer extends Component {
  render() {
    return (
      <div >
        <button
          className="btn btn-primary btn-circle"
          id="to-top"
          style={{ zIndex: 99999 }}
          onClick={scrollToTop}
        >
          <i className="fa fa-angle-up" />
        </button>
        <footer id="footer" className=" footer bg-light footer-light">
          <div className="container-fluid col-md-11">
            <div className="row">
              <div className="col-lg-3">
                <p>
                  <img
                    src={
                      process.env.REACT_APP_BACKEND_CONTENT +
                      "logo/" +
                      this.props.content.logo
                    }
                    alt="Logo Here"
                    style={{ height: "50px" }}
                  />
                </p>
                <address className="mb-3">
                  <strong>{this.props.content.company_name}</strong>
                  <br />
                  {this.props.content.tole},{this.props.content.address}
                  <br />
                  {this.props.content.city}, {this.props.content.country}
                  <br />
                  <abbr title="Phone">P:</abbr>
                  {this.props.content.phone}
                </address>
              </div>
              <div className="col-lg-2 col-sm-4">
                <div className="footer-links">
                  <ul className="list-unstyled">
                    <li className="list-title">
                      <Link to="/about">Quick Links</Link>
                    </li>
                    <li>
                      <Link to="/realestates/list">Realestates</Link>
                    </li>
                    <li>
                      <Link to="/vehicles/list">Vehicles</Link>
                    </li>

                    <li>
                      <Link to="/company/list">Company</Link>
                    </li>
                    <li>
                      <Link to="/jobs/list">Jobs</Link>
                    </li>
                    <li>
                      <Link to="/services/list">Services</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-sm-4">
                <div className="footer-links">
                  <ul className="list-unstyled">
                    <li className="list-title">
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Terms and Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-sm-4">
                <div className="footer-links">
                  <ul className="list-unstyled">
                    <li className="list-title">
                      <Link to="/#">Help</Link>
                    </li>

                    <li>
                      <Link to="/#">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/#">Report</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="text-center">
                  <img src="/img/store/google.svg" width={140} alt="" />

                  <img
                    src="/img/store/apple.svg"
                    className=" mx-2 my-2"
                    width={140}
                    alt=""
                  />
                </div>
                <div className="text-lg-center ml-lg-2">
                  <div className="footer-social mb-4">
                    <Link to="/#" className="ml-2 mr-2">
                      <span className="fa fa-twitter" />
                    </Link>
                    <Link to="/#" className="ml-2 mr-2">
                      <span className="fa fa-facebook" />
                    </Link>
                    <Link to="/#" className="ml-2 mr-2">
                      <span className="fa fa-instagram" />
                    </Link>
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="footer-credits d-lg-flex justify-content-lg-between align-items-center">
              <div>{this.props.content.copyright}</div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    content: state.content.content,
  };
};

export default connect(mapStateToProps, null)(Footer);
