import React from "react";

const FeaturedAds = ({ image }) => {
  return image && image.banner ? (
    <section className="ads" style={{ width: "100vw", background: "#F6F8F8" }}>
      <div className="container-fluid grey">
        <div
          className="row justify-content-center"
          style={{ maxHeight: 120, width: "100vw", background: "#F6F8F8" }}
        >
          <div className="col-md-11">
            {image.banner && <img
              src={`${process.env.REACT_APP_BACKEND_CONTENT}ads/${image.banner}`}
              style={{ height: "100%", width: "100%" }}
            />}
          </div>
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default FeaturedAds;
