import React, { Component } from "react";
import Axios from "axios";
import { Spinner } from "../../utilities/Spinner";
import { withRouter } from "react-router";
import cookies from "js-cookie";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { store } from "react-notifications-component";

class MyPasswordBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      oldPassword: "",
      newPasswordConfirm: "",
      isLoading: false,
      isSubmitting: false,
      isUploading: false,
    };
    this.validator = new SimpleReactValidator();
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      isSubmitting: true,
    });
    if (this.state.newPassword === this.state.newPasswordConfirm) {
      this.updatePassword();
    } else {
      alert("Your password doesn't match");
    }
  };
  updatePassword = () => {
    this.setState({
      loading: true,
    });
    if (this.validator.allValid()) {
      const data = {
        previouspassword: this.state.oldPassword,
        newpassword: this.state.newPassword,
        newpassword_confirmation: this.state.newPasswordConfirm,
      };
      Axios.post(process.env.REACT_APP_API + "userprofile/password", data)
        .then((response) => {
          cookies.remove("token");
          this.props.logout();
          this.setState({
            loading: false,
          });
          store.addNotification({
            title: "Successful!",
            message: "Your password has been changed.Please log in again",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
          this.props.history.push("/");
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({
        loading: false,
      });
    }
  };
  render() {
    return this.state.isLoading ? (
      <div className="col-md-7 col-lg-8 col-xl-8">
        <Spinner />
      </div>
    ) : (
      <div className="col-md-7 col-lg-8 col-xl-8">
        <div className="page-header bordered">
          <h1>Change Password</h1>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label>Your current password</label>
            <input
              type="password"
              name="oldPassword"
              className="form-control form-control-lg"
              placeholder="Your current password"
              defaultValue={this.state.oldPassword}
              onChange={this.handleChange}
              autofocus
            />
          </div>
          <div className="text-danger my-2">
            {this.validator.message(
              "oldPassword",
              this.state.oldPassword,
              "required"
            )}
          </div>
          <div className="form-group">
            <label>Your new password</label>
            <input
              type="password"
              name="newPassword"
              className="form-control form-control-lg"
              defaultValue={this.state.newPassword}
              onChange={this.handleChange}
              placeholder="Your new password"
            />
          </div>
          <div className="text-danger my-2">
            {this.validator.message(
              "newPassword",
              this.state.newPassword,
              "required"
            )}
          </div>
          <div className="form-group">
            <label>Repeat new password</label>
            <input
              type="password"
              name="newPasswordConfirm"
              defaultValue={this.state.newPasswordConfirm}
              onChange={this.handleChange}
              className="form-control form-control-lg"
              placeholder="Repeat new password"
            />
          </div>
          <div className="text-danger my-2">
            {this.validator.message(
              "newPasswordConfirm",
              this.state.newPasswordConfirm,
              "required"
            )}
          </div>
          <hr />
          <div className="form-group action">
            <button type="submit" className="btn btn-lg btn-primary">
              Update Password
            </button>
          </div>
        </form>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
export default connect(null, mapDispatchToProps)(withRouter(MyPasswordBody));
