import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Axios from "axios";
export default class VehicleSEO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seo: [],
    };
  }
  componentDidMount() {
    Axios.get(process.env.REACT_APP_API + "seo")
      .then((response) => {
        this.setState({
          seo: response.data.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }
  render() {
    return (
      <Helmet>
        {this.state.seo.map((item) => {
          if (item.page === "vehiclepage") {
            return <meta name="title" content={item.seo_title} />;
          }
        })}
        {this.state.seo.map((item) => {
          if (item.page === "vehiclepage") {
            return <meta name="description" content={item.meta_description} />;
          }
        })}
        {this.state.seo.map((item) => {
          if (item.page === "vehiclepage") {
            return <meta name="keywords" content={item.meta_keywords} />;
          }
        })}
      </Helmet>
    );
  }
}
