import React, { Component } from "react";
import Axios from "axios";
import { Spinner } from "../../utilities/Spinner";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { store } from "react-notifications-component";

class MyProfileBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      address: "",
      phone: "",
      photo: "",
      email: "",
      prevPhoto: "",
      isLoading: false,
      isSubmitting: false,
      isUploading: false,
    };
  }
  componentDidMount() {
    this.getProfileData();
  }

  getProfileData = () => {
    this.setState({ isLoading: true });
    Axios.get(process.env.REACT_APP_API + "userprofile")
      .then((response) => {
        const profile = response.data.data;
        this.setState({
          firstname: profile.firstname,
          lastname: profile.lastname,
          address: profile.tole,
          city: profile.city,
          district: profile.district,
          phone: profile.phone,
          photo: profile.photo,
          email: profile.email,
          prevPhoto: profile.photo,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.log(error.response);
      });
  };

  handleFileChange = (e) => {
    this.setState({ photo: e.target.files[0], isUploading: true });
  };
  handleCancelClick = () => {
    this.getProfileData();
    this.setState({
      isUploading: false,
    });
  };
  handleUpload = (event) => {
    event.preventDefault();
    this.setState({
      isSubmitting: true,
    });
    const data = new FormData();
    data.append("photo", this.state.photo, this.state.photo.name);
    this.updateProfile(data);
  };
  updateProfile = (data) => {
    const submitData = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      tole: this.state.address,
      district: this.state.district,
      city: this.state.city,
      email: this.state.email,
      phone: this.state.phone,
    };
    for (var key in submitData) {
      data.append(key, submitData[key]);
    }
    Axios.post(process.env.REACT_APP_API + "userprofile", data)
      .then((response) => {
        this.setState({
          isUploading: false,
        });
        store.addNotification({
          title: "Successful",
          message: "Your profile picture was uploaded successfully",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
          },
        });
        this.props.history.push("/user/profile");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    return this.state.isLoading ? (
      <div className="col-md-7 col-lg-8 col-xl-8">
        <div>
          <Spinner />
        </div>
      </div>
    ) : (
      <div className="col-md-7 col-lg-8 col-xl-8 mb-5">
        <div className="container-fluid">
          <div className="page-header bordered">
            <h1>My Profile</h1>
          </div>
          <div className="row my-2">
            <div className="col-lg-8 order-lg-2">
              <ul className="list-group">
                <li className="list-group-item profile-item">
                  <span className="profile-list">Name: </span>
                  {this.state.firstname + " " + this.state.lastname}
                </li>
                <li className="list-group-item profile-item">
                  <span className="profile-list">Email: </span>
                  {this.state.email}
                </li>{" "}
                <li className="list-group-item profile-item">
                  <span className="profile-list">Address: </span>
                  {this.state.address}
                </li>{" "}
                <li className="list-group-item profile-item">
                  <span className="profile-list">City: </span>
                  {this.state.city}
                </li>{" "}
                <li className="list-group-item profile-item">
                  <span className="profile-list">District: </span>
                  {this.state.district}
                </li>{" "}
                <li className="list-group-item profile-item">
                  <span className="profile-list">Phone: </span>
                  {this.state.phone}
                </li>{" "}
              </ul>
              <Link
                to="/user/profile/edit"
                className="btn btn-sm btn-info mt-3"
              >
                <i className="fa fa-edit px-2"></i>Edit Profile
              </Link>
            </div>
            <div className="col-lg-4 order-lg-1 text-center">
              {typeof this.state.photo === "string" ? (
                <img
                  src={
                    process.env.REACT_APP_BACKEND_CONTENT +
                    "images/profilepictures/" +
                    this.state.photo
                  }
                  className="mx-auto img-fluid img-circle d-block img-fluid"
                  alt="avatar"
                />
              ) : (
                <img
                  src={
                    this.state.photo
                      ? URL.createObjectURL(this.state.photo)
                      : "/img/profile-placeholder.jpg"
                  }
                  className="mx-auto img-fluid img-circle d-block"
                  alt="avatar"
                />
              )}

              {this.state.isUploading ? (
                <div>
                  <button
                    className="btn btn-info btn-sm mt-3 mx-3"
                    onClick={this.handleUpload}
                  >
                    Upload
                  </button>

                  <button
                    className="btn btn-danger btn-sm mt-3"
                    onClick={this.handleCancelClick}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <label className="custom-file">
                  <input
                    type="file"
                    onChange={this.handleFileChange}
                    className="custom-file-input"
                  />
                  <span className="custom-file-control">Upload New Photo</span>
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(MyProfileBody);
