import React, { Component, Fragment } from "react";
import { Button, Modal } from "react-bootstrap";

import { Link } from "react-router-dom";
import { withRouter } from "react-router";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  render() {
    return (
      <Fragment>
        <div className="col-md-5 col-lg-4 col-xl-4 col-sidebar sticky-top">
          <div id="sidebar" className="sidebar-left">
            <div className="sidebar_inner">
              <div className="list-group no-border list-unstyled">
                <span className="list-group-item heading">Manage Listings</span>
                <a className="nav-link nav-btn" onClick={this.handleShow}>
                  <i className="fa fa-fw fa-plus-square-o pl-2" /> &nbsp;Add
                  Listing
                </a>

                <Link
                  to="/user/my-listing"
                  className={
                    this.props.location.pathname === "/user/my-listing"
                      ? "list-group-item d-flex justify-content-between align-items-center active"
                      : "list-group-item d-flex justify-content-between align-items-center"
                  }
                >
                  <span>
                    <i className="fa fa-fw fa-bars" /> My Listings
                  </span>
                </Link>
                <span className="list-group-item heading">Manage Account</span>
                <Link
                  to="/user/profile"
                  className={
                    this.props.location.pathname === "/user/profile"
                      ? "list-group-item active"
                      : "list-group-item"
                  }
                >
                  <i className="fa fa-fw fa-pencil" /> My Profile
                </Link>
                <Link
                  to="/user/password"
                  className={
                    this.props.location.pathname === "/user/password"
                      ? "list-group-item active"
                      : "list-group-item"
                  }
                >
                  <i className="fa fa-fw fa-lock" /> Change Password
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.show}
          onHide={this.handleClose}
          keyboard={false}
        >
          <Modal.Header style={{ border: "none", marginBottom: "10px" }}>
            <button type="button" className="close" onClick={this.handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <Link to="/realestate/add" onClick={this.handleClose}>
                    <div className="card d-flex align-items-center justify-content-center add-category-realestate">
                      Add a Realestate
                    </div>
                  </Link>
                </div>
                <div className="col-md-10">
                  <Link to="/vehicle/add" onClick={this.handleClose}>
                    <div className="card d-flex align-items-center justify-content-center add-category-vehicle">
                      Add a Vehicle
                    </div>
                  </Link>
                </div>

                <div className="col-md-10">
                  <Link to="/company/add" onClick={this.handleClose}>
                    <div className="card d-flex align-items-center justify-content-center add-category-company">
                      Add a Company
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}
export default withRouter(Sidebar);
