import React, { Component } from "react";
import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
export default class AllFeatured extends Component {
  render() {
    const params = {
      slidesPerView: 3,
      spaceBetween: 50,
      loop: true,

      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      loopFillGroupWithBlank: true,
      navigation: {
        nextEl: ".swiper-button-next.customized-swiper-button-next",
        prevEl: ".swiper-button-prev.customized-swiper-button-prev",
      },
      centered: true,
      rebuildOnUpdate: true,
      breakpoints: {
        1920: {
          slidesPerView: 6,
          spaceBetween: 50,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 40,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    };
    return (
      <div className="feature-box centered gray m-0 pt-4">
        <div>
          <div className="container-fluid">
            <div className="row justify-content-md-center">
              <div className="col col-lg-12 col-xl-11">
                <div className="main-title">
                  <span>Our Featured Listings</span>
                </div>
                <div className="clearfix" />

                <Swiper {...params}>
                  {this.props.data.map((data, key) => {
                    const backgroundImage =
                      process.env.REACT_APP_BACKEND_CONTENT +
                      "images/" +
                      data.photos;

                    return (
                      <div key={key} className="card p-0">
                        <Link
                          to={"realestate/view/" + data.id}
                          className="text-dark"
                        >
                          <img
                            className="card-img-top"
                            src={
                              data.photos !== null
                                ? backgroundImage
                                : "img/default.png"
                            }
                            alt="Card image cap"
                          />
                          <div className="card-body p-2">
                            <h5 className="card-title featured-card-title mx-1   text-truncate text-capitalize mb-0">
                              {data.featured_name}
                            </h5>
                            <div className="badge badge-success badge-featured">
                              Featured
                            </div>
                            <div className="location text-truncate  ">
                              <i className="fa fa-map-marker mx-1 pb-4 pt-2"></i>
                              {data.featured_address +
                                ", " +
                                data.featured_district}
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
