import React, { Component, Fragment } from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import Axios from "axios";
import { withRouter } from "react-router";
import queryString from "query-string";
import { Spinner } from "../utilities/Spinner";
import defaultImage from "./default.jpg";
class EventsBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      events: [],
      isLoading: false,
      currentPage: "",
      perPage: "",
      totalPage: "",
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const values = queryString.parse(this.props.location.search);
    const prevValues = queryString.parse(prevProps.location.search);

    if (values.page !== prevValues.page) {
      this.setState(
        {
          page: values.page,
        },
        () => this.getEvents()
      );
    }
  }
  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    if (values.page) {
      this.setState(
        {
          page: values.page,
        },
        () => this.getEvents()
      );
    } else {
      this.getEvents();
    }
  }

  getEvents = () => {
    this.setState({ isLoading: true });
    Axios.get(process.env.REACT_APP_API + "events?page=" + this.state.page)
      .then((response) => {
        this.setState({
          isLoading: false,
          events: response.data.data.data,
          currentPage: response.data.data.current_page,
          perPage: response.data.data.per_page,
          totalPage: response.data.data.total,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        console.log(error);
      });
  };
  getData = (pageNumber) => {
    this.setState(
      {
        page: pageNumber,
      },
      () => {
        this.getEvents();
        this.props.history.push("/events?page=" + this.state.page);
      }
    );
  };
  stripHtml = (html) => {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };
  textTruncuate = (text) => {
    const actualText = this.stripHtml(text);
    if (actualText.length > 500) {
      return text.substring(0, 500) + "...";
    } else {
      return text;
    }
  };
  render() {
    const events = this.state.events.map((item) => {
      return (
        <div key={item.id} className=" col-md-6 item item-lg">
          <div className="item-image">
            <Link to={"/news/" + item.id}>
              {item.image === null ? (
                <img
                  className="img-fluid"
                  alt=""
                  src={defaultImage}
                  style={{
                    height: "300px",
                    width: "100%",
                  }}
                />
              ) : (
                <img
                  className="img-fluid"
                  alt=""
                  style={{
                    height: "300px",
                    width: "100%",
                    backgroundImage:
                      `https://buyandsellnepal.tech/newsevents/` + item.image,
                  }}
                />
              )}
            </Link>
          </div>
          <h3 className="item-title">{item.title}</h3>
          <div className="item-meta-info">
            <span className="date">
              <Moment fromNow ago>
                {item.created_at}
              </Moment>{" "}
              ago
            </span>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: this.textTruncuate(item.description),
            }}
          ></div>

          <Link to={"/events/" + item.id} className="read-more">
            Read More
          </Link>
        </div>
      );
    });

    return (
      <div id="content">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col col-lg-12 col-xl-11">
              <div className="row has-sidebar">
                <div className="col-md-9 col-lg-9 col-xl-9">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Events
                      </li>
                    </ol>
                  </nav>
                  <div className="page-header v2 bordered mb0">
                    <h1>Events</h1>
                  </div>
                  <div className="clearfix" />
                  <div className="item-listing grid">
                    {this.state.isLoading ? (
                      <Spinner />
                    ) : (
                      <div className="container mt-3">
                        <div className="row"> {events}</div>
                      </div>
                    )}
                  </div>
                  <nav aria-label="Page navigation">
                    <ul className="pagination  mb-5">
                      {!this.state.isLoading &&
                        this.state.events.length !== 0 && (
                          <Pagination
                            activePage={Number(this.state.currentPage)}
                            itemsCountPerPage={Number(this.state.perPage)}
                            totalItemsCount={Number(this.state.totalPage)}
                            onChange={this.getData}
                            prevPageText="«"
                            nextPageText="»"
                            hideFirstLastPages={true}
                          />
                        )}
                    </ul>
                  </nav>
                </div>
                <div className="col-md-3 col-lg-3 col-xl-3 col-sidebar">
                  <Sidebar state={this.state} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(EventsBody);
