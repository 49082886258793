import React, { Component, Fragment } from "react";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import cookies from "js-cookie";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { Link } from "react-router-dom";
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      profile: "",
      show: false,
      realestatecategory: [],
      vehiclecategory: [],
      jobscategory: [],
      servicescategory: [],
      companycategory: [],
    };
    if (this.props.loggedIn) {
      this.state.loggedIn = true;
      this.userDetails();
    }
  }

  userDetails = () => {
    let token = cookies.get("token");
    Axios.defaults.headers.common["Accept"] = "application/json";
    Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    Axios.get(process.env.REACT_APP_API + "userprofile").then((response) => {
      this.setState({
        profile: response.data.data,
      });
    });
  };
  componentDidMount() {
    this.apiCallRealestate("realestatecategory");
    this.apiCallVehicle("vehiclecategory");
    this.apiCallJob("jobcategory");
    this.apiCallCompany("companycategory");
    this.apiCallServices("servicecategory");
  }

  apiCallRealestate = (data) => {
    Axios.get(process.env.REACT_APP_API + data)
      .then((response) => {
        this.setState({
          realestatecategory: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  apiCallServices = (data) => {
    Axios.get(process.env.REACT_APP_API + data)
      .then((response) => {
        this.setState({
          servicescategory: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  apiCallCompany = (data) => {
    Axios.get(process.env.REACT_APP_API + data)
      .then((response) => {
        this.setState({
          companycategory: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  apiCallVehicle = (data) => {
    Axios.get(process.env.REACT_APP_API + data)
      .then((response) => {
        this.setState({
          vehiclecategory: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  apiCallJob = (data) => {
    Axios.get(process.env.REACT_APP_API + data)
      .then((response) => {
        this.setState({
          jobscategory: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleLogout = (e) => {
    cookies.remove("token");
    this.props.logout();
    this.props.history.push("/");
  };
  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.setState({
        loggedIn: this.props.loggedIn,
      });
    }
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  render() {
    const profile = this.state.profile;

    return (
      <Fragment>
        <nav className="topbar">
          <div className="container-fluid col-md-11 flex-row ">
            <div className="float-left">
              <span>
                <i className="fa fa-phone px-2"></i>
                {this.props.content.phone}
              </span>
              <span>
                <i className="fa fa-envelope pl-4 pr-2"></i>
                info@digitalsamadhan.com
              </span>
            </div>
            <div className="float-right">
              Follow us on:
              <a className="text-light pl-3" href="#" target="_blank">
                <i className="fa fa-facebook"></i>
              </a>
              <a className="text-light pl-3" href="#" target="_blank">
                <i className="fa fa-instagram"></i>
              </a>
              <a className="text-light pl-3" href="#" target="_blank">
                <i className="fa fa-youtube"></i>
              </a>
            </div>
          </div>
        </nav>
        <nav className="navbar navbar-expand-lg navbar-dark" id="menu">
          <div className="container-fluid col-md-11">
            <Link className="navbar-brand" to="/">
              <img
                src={
                  process.env.REACT_APP_BACKEND_CONTENT +
                  "logo/" +
                  this.props.content.logo
                }
                alt="Logo Here"
                style={{ height: "50px" }}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#menu-content"
              aria-controls="menu-content"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="menu-content">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Realestate
                  </a>
                  <div className="dropdown-menu">
                    {this.state.realestatecategory.map((category) => {
                      return (
                        <Link
                          key={category.id}
                          to={
                            "/realestate/list?category=" +
                            encodeURIComponent(category.category_name)
                          }
                          className="dropdown-item"
                        >
                          {category.category_name}
                        </Link>
                      );
                    })}
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Vehicle
                  </a>
                  <div className="dropdown-menu">
                    {this.state.vehiclecategory.map((category) => {
                      return (
                        <Link
                          key={category.id}
                          to={
                            "/vehicle/list?category=" +
                            encodeURIComponent(category.category_name)
                          }
                          className="dropdown-item"
                        >
                          {category.category_name}
                        </Link>
                      );
                    })}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Company
                  </a>
                  <div className="dropdown-menu">
                    {this.state.companycategory.map((category) => {
                      return (
                        <Link
                          key={category.id}
                          to={
                            "/company/list?category=" +
                            encodeURIComponent(category.category_name)
                          }
                          className="dropdown-item"
                        >
                          {category.category_name}
                        </Link>
                      );
                    })}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Jobs
                  </a>
                  <div className="dropdown-menu">
                    {this.state.jobscategory.map((category) => {
                      return (
                        <Link
                          key={category.id}
                          to={
                            "/jobs/list?category=" +
                            encodeURIComponent(category.category_name)
                          }
                          className="dropdown-item"
                        >
                          {category.category_name}
                        </Link>
                      );
                    })}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Services
                  </a>
                  <div className="dropdown-menu">
                    {this.state.servicescategory.map((category) => {
                      return (
                        <Link
                          key={category.id}
                          to={
                            "/services/list?category=" +
                            encodeURIComponent(category.category_name)
                          }
                          className="dropdown-item"
                        >
                          {category.category_name}
                        </Link>
                      );
                    })}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Pages
                  </a>
                  <div className="dropdown-menu">
                    <Link className="dropdown-item" to={{ pathname: "/news" }}>
                      News
                    </Link>

                    <Link className="dropdown-item" to="/events">
                      Events
                    </Link>
                    <Link className="dropdown-item" to="/contact">
                      Contact
                    </Link>
                  </div>
                </li>
                <li className="nav-item ">
                  <Link className="nav-link" to="/about">
                    About Us <span className="sr-only">(current)</span>
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav ml-auto">
                {this.state.loggedIn ? (
                  <li className="nav-item dropdown user-account">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span
                        className="user-image"
                        style={{
                          backgroundImage: profile.photo
                            ? `url(${process.env.REACT_APP_BACKEND_CONTENT}images/profilepictures/${profile.photo})`
                            : "url(/img/profile-placeholder.jpg)",
                        }}
                      />
                      {profile.firstname}
                    </a>
                    <div className="dropdown-menu">
                      <Link to="/user/my-listing" className="dropdown-item">
                        My Listing
                      </Link>
                      <Link to="/user/profile" className="dropdown-item">
                        My Profile
                      </Link>

                      <a onClick={this.handleLogout} className="dropdown-item">
                        Logout
                      </a>
                    </div>
                  </li>
                ) : (
                  <li className="nav-item">
                    <Link className="nav-link nav-btn" to="/login">
                      Login / Register
                    </Link>
                  </li>
                )}
                {this.state.loggedIn ? (
                  <Fragment>
                    <li className="nav-item">
                      <a className="nav-link nav-btn" onClick={this.handleShow}>
                        <span>
                          <i className="fa fa-plus" aria-hidden="true" /> Add
                          listing
                        </span>
                      </a>
                    </li>
                    <Modal
                      size="lg"
                      show={this.state.show}
                      onHide={this.handleClose}
                      keyboard={false}
                    >
                      <Modal.Header
                        style={{ border: "none", marginBottom: "10px" }}
                      >
                        <button
                          type="button"
                          className="close"
                          onClick={this.handleClose}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="container-fluid">
                          <div className="row justify-content-center">
                            <div className="col-md-10">
                              <Link
                                to="/realestate/add"
                                onClick={this.handleClose}
                              >
                                <div className="card d-flex align-items-center justify-content-center add-category-realestate">
                                  Add a Realestate
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-10">
                              <Link
                                to="/vehicle/add"
                                onClick={this.handleClose}
                              >
                                <div className="card d-flex align-items-center justify-content-center add-category-vehicle">
                                  Add a Vehicle
                                </div>
                              </Link>
                            </div>

                            <div className="col-md-10">
                              <Link
                                to="/company/add"
                                onClick={this.handleClose}
                              >
                                <div className="card d-flex align-items-center justify-content-center add-category-company">
                                  Add a Company
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </Fragment>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </nav>

        <ReactNotification />
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    content: state.content.content,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
