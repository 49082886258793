import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Spinner } from "../../../utilities/Spinner";

export default class ListView extends Component {
  render() {
    const items = this.props.data.map((key) => {
      return (
        <div className="item" key={key.service_id}>
          <div className="row">
            <div className="col-lg-5 col-sm-8 col-xs-10">
              <div className="item-image">
                <Link to={"/services/view/" + key.service_id}>
                  <img
                    alt="buyandsell"
                    src={
                      key.photos
                        ? process.env.REACT_APP_BACKEND_CONTENT +
                          "images/" +
                          key.photos
                        : "img/default.png"
                    }
                    className="img-fluid"
                    style={{ height: 250, width: "100%" }}
                  />
                  <div className="item-badges">
                    <div className="item-badge-right">{key.service_type}</div>
                  </div>
                  <div className="item-meta">
                    <div className="item-price">Rs.{key.price}</div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="item-info">
                <h3 className="item-title">
                  <Link to={"/services/view/" + key.service_id}>
                    {key.service_title}
                  </Link>
                </h3>
                <div className="item-location">
                  <i className="fa fa-map-marker" /> {key.service_address},
                  {key.services_district}
                </div>
                <div className="item-details-i">
                  <span
                    className="bedrooms"
                    data-toggle="tooltip"
                    title="Price Status"
                  >
                    {key.price_status === "fixed" ? "Fixed" : "Negotiable"}
                    <i className="fa fa-money px-2" />
                  </span>
                  {/* <span
                    className="bathrooms"
                    data-toggle="tooltip"
                    title="Condition"
                  >
                    {key.condition}
                    <i className="fa fa-exclamation-circle px-2" />
                  </span> */}
                </div>
                <div className="item-details">
                  <ul>
                    <li>
                      Tole <span>{key.services_tole}</span>
                    </li>

                    <li>
                      Type <span>Services</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="added-on">
                    Listed on:
                    <Moment format="YYYY/MM/DD">{key.listed_on}</Moment>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        {items.length && !this.props.isLoading ? (
          items
        ) : this.props.isLoading ? (
          <Spinner />
        ) : (
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <h5>No data found in the record.</h5>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
