import React, { Component } from "react";
import Navbar from "../partials/Navbar";
import Footer from "../partials/Footer";
import NewsSingleBody from "./NewsSingleBody";

export default class NewsSingle extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <NewsSingleBody />
        <Footer />
      </div>
    );
  }
}
