import React, { Component, Fragment } from "react";
import SimpleReactValidator from "simple-react-validator";
import Axios from "axios";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import FacebookLoginView from "../partials/FacebookLoginView";
import GoogleLoginView from "../partials/GoogleLoginView";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import cookie from "js-cookie";
class LoginView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      isValidated: false,
      errorMessage: "",
      data: {
        email: "",
        password: "",
      },
    };

    this.validator = new SimpleReactValidator();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    const { data } = { ...this.state };
    const currentState = data;
    const { name, value } = event.target;
    currentState[name] = value;
    this.setState({ data: currentState });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        loading: true,
      });
      const data = {
        password: this.state.data.password,
        email: this.state.data.email,
      };
      event.preventDefault();
      Axios.defaults.headers.common["Accept"] = "application/json";
      Axios.post(process.env.REACT_APP_API + "login", data)
        .then((response) => {
          this.setState({
            redirect: true,
            loading: false,
          });
          cookie.set("token", response.data.token.access_token, { path: "/" });
          this.props.setLogin(response.data.username);
          this.props.history.push("/");
          store.addNotification({
            title: "Successful",
            message: "Your have been logged in successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              showIcon: true,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            error: true,
            isValidated: true,
            errorMessage: error.response.data.message,
            loading: false,
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({
        isValidated: true,
        loading: false,
      });
    }
  };
  render() {
    return (
      <Fragment>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col col-md-12 col-lg-10 col-xl-8">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    Login
                  </li>
                </ol>
              </nav>
              <div className="page-header">
                <h1>Please sign in or register</h1>
              </div>
            </div>
          </div>
        </div>
        <div id="content" className="pt0 pb0">
          <div className="container">
            <div className="row justify-content-md-center align-items-center">
              <div className="col col-md-6  col-lg-5 col-xl-4">
                {this.state.error ? (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.errorMessage}
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <ul className="nav nav-tabs tab-lg" role="tablist">
                  <li role="presentation" className="nav-item">
                    <Link className="nav-link active" to="/login">
                      Sign In
                    </Link>
                  </li>
                  <li role="presentation" className="nav-item">
                    <Link className="nav-link" to="/register">
                      Register
                    </Link>
                  </li>
                </ul>
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" id="login">
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="email">Email address</label>
                        <input
                          type="email"
                          id="email"
                          className={
                            this.state.isValidated
                              ? "form-control form-control-lg form-error"
                              : "form-control form-control-lg "
                          }
                          name="email"
                          value={this.state.data.email}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="text-danger pb-2">
                        {this.validator.message(
                          "email",
                          this.state.data.email,
                          "required|email"
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          id="password"
                          className="form-control form-control-lg"
                          placeholder="Password"
                          name="password"
                          value={this.state.data.password}
                          onChange={this.handleChange}
                        />
                        <div className="text-danger pb-2">
                          {this.validator.message(
                            "password",
                            this.state.data.password,
                            "required"
                          )}
                        </div>
                      </div>
                      <p className="text-lg-right">
                        <Link to="/forgot-password">Forgot Password ?</Link>
                      </p>
                      <div className="checkbox">
                        <input type="checkbox" id="remember_me" />
                        <label htmlFor="remember_me">Remember Me</label>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg"
                        disabled={this.state.loading}
                      >
                        {this.state.loading && (
                          <i className="fa  fa-circle-o-notch fa-spin px-2"></i>
                        )}
                        Sign In
                      </button>
                    </form>
                    {/* <ToastContainer /> */}
                  </div>
                </div>
                <div> </div>
              </div>
              <div className="col-md-6 col-lg-5 col-xl-4">
                <div className="social-login-buttons">
                  <FacebookLoginView />
                  <GoogleLoginView />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) => dispatch({ type: "SET_LOGIN", payload: user }),
  };
};
export default connect(null, mapDispatchToProps)(withRouter(LoginView));
