import React, { Component } from "react";
import Home from "./components/Home/Home";
import { Switch, Route, withRouter } from "react-router-dom";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import News from "./components/News/News";
import GuestRoute from "./components/utilities/routes/GuestRoute";
import AuthRoute from "./components/utilities/routes/AuthRoute";

import RealEstateSingle from "./components/View/RealEstate/SingleView/SingleView";
import RealEstateList from "./components/View/RealEstate/List/RealEstate";
import RealEstateGrid from "./components/View/RealEstate/Grid/RealEstateGrid";
import RealEstateMap from "./components/View/RealEstate/Map/RealEstateMap";
import RealEstateAddListing from "./components/View/RealEstate/AddListing/AddListing";
import RealEstateEditListing from "./components/View/RealEstate/EditListing/EditListing";

import CompanyList from "./components/View/Company/List/CompanyList";
import CompanyGrid from "./components/View/Company/Grid/CompanyGrid";
import CompanyMap from "./components/View/Company/Map/CompanyMap";

import CompanySingle from "./components/View/Company/SingleView/SingleView";
import CompanyAddListing from "./components/View/Company/AddListing/AddListing";
import CompanyEditListing from "./components/View/Company/EditListing/EditListing";

import VehicleList from "./components/View/Vehicle/List/Vehicle";
import VehicleGrid from "./components/View/Vehicle/Grid/VehicleGrid";
import VehicleMap from "./components/View/Vehicle/Map/VehicleMap";

import VehicleAddListing from "./components/View/Vehicle/AddListing/AddListing";
import VehicleSingle from "./components/View/Vehicle/SingleView/SingleView";
import VehcileEditListing from "./components/View/Vehicle/EditListing/EditListing";

import JobsList from "./components/View/Jobs/List/Jobs";
import JobsGrid from "./components/View/Jobs/Grid/JobsGrid";
import JobsMap from "./components/View/Jobs/Map/JobsMap";
import JobsSingle from "./components/View/Jobs/SingleView/SingleView";

import ServicesList from "./components/View/Services/List/Services";
import ServicesGrid from "./components/View/Services/Grid/ServicesGrid";
import ServicesMap from "./components/View/Services/Map/ServicesMap";
import ServicesSingle from "./components/View/Services/SingleView/SingleView";

import MyProfile from "./components/View/MyProfile/MyProfile";
import EditProfile from "./components/View/EditProfile/EditProfile";
import EditPassword from "./components/View/MyPassword/MyPassword";
import MyListing from "./components/View/MyListing/MyListing";
import SingleNews from "./components/News/NewsSingle";
import ResetPassword from "./components/PasswordReset/PasswordReset";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import Error404 from "./components/View/Messages/Error";
import PasswordReset from "./components/PasswordReset/PasswordReset";
import Events from "./components/Events/Events";
import EventsSingle from "./components/Events/EventsSingle";

class App extends Component {
  componentDidMount() {
    window.lib();
    window.lib2();
  }
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <GuestRoute exact path="/login" component={Login} />
        <GuestRoute exact path="/register" component={Register} />

        <Route exact path="/about" component={About} />
        <AuthRoute exact path="/user/my-listing" component={MyListing} />
        <AuthRoute exact path="/user/profile" component={MyProfile} />
        <AuthRoute exact path="/user/profile/edit" component={EditProfile} />
        <AuthRoute exact path="/user/password" component={EditPassword} />
        <Route exact path="/news" component={News} />
        <Route exact path="/news/:id" component={SingleNews} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/events/:id" component={EventsSingle} />
        <Route exact path="/contact" component={Contact} />
        <Route
          exact
          path={"/realestate/view/:id"}
          component={RealEstateSingle}
        />
        <Route exact path="/realestate/list" component={RealEstateList} />
        <Route exact path="/realestate/grid" component={RealEstateGrid} />
        <Route exact path="/realestate/map" component={RealEstateMap} />

        <AuthRoute
          exact
          path="/realestate/add"
          component={RealEstateAddListing}
        />
        <Route
          exact
          path="/realestate/edit/:id"
          component={RealEstateEditListing}
        />
        <AuthRoute exact path="/company/add" component={CompanyAddListing} />
        <Route exact path="/company/list" component={CompanyList} />
        <Route exact path="/company/grid" component={CompanyGrid} />
        <Route exact path="/company/map" component={CompanyMap} />

        <Route exact path={"/company/view/:id"} component={CompanySingle} />
        <AuthRoute
          exact
          path="/company/edit/:id"
          component={CompanyEditListing}
        />
        <Route exact path="/forgot-password" component={ForgetPassword} />

        <Route exact path="/vehicle/list" component={VehicleList} />
        <AuthRoute exact path="/vehicle/add" component={VehicleAddListing} />
        <Route exact path={"/vehicle/view/:id"} component={VehicleSingle} />
        <Route exact path="/vehicle/grid" component={VehicleGrid} />
        <Route exact path="/vehicle/map" component={VehicleMap} />

        <AuthRoute
          exact
          path="/vehicle/edit/:id"
          component={VehcileEditListing}
        />
        <Route exact path={"/jobs/view/:id"} component={JobsSingle} />
        <Route exact path="/jobs/list" component={JobsList} />
        <Route exact path="/jobs/grid" component={JobsGrid} />
        <Route exact path="/jobs/map" component={JobsMap} />
        <Route exact path={"/services/view/:id"} component={ServicesSingle} />
        <Route exact path="/services/list" component={ServicesList} />
        <Route exact path="/services/grid" component={ServicesGrid} />
        <Route exact path="/services/map" component={ServicesMap} />
        <Route exact path="/forgot-password/reset" component={PasswordReset} />
        <Route exact path="*" component={Error404} />
      </Switch>
    );
  }
}
export default withRouter(App);
