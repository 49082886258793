import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { store } from "react-notifications-component";
import axios from "axios";
class ForgetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    console.log({
      email: this.state.email,
    });
    axios
      .post(process.env.REACT_APP_API + "password/create", {
        email: this.state.email,
      })
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        this.props.history.push("/login");
        store.addNotification({
          title: "Successful",
          message: "A password reset link has been sent to your email.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 30000,
            showIcon: true,
          },
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  };
  render() {
    return (
      <Fragment>
        <div className="container  mt-5 ">
          <div className="row justify-content-md-center">
            <div className="col col-md-8  col-lg-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">Account</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Forgot Password
                  </li>
                </ol>
              </nav>
              <div className="page-header">
                <h1>Forgot Password</h1>
              </div>
            </div>
          </div>
        </div>
        <div id="content">
          <div className="container-fluid col-md-11">
            <div className="row justify-content-md-center">
              <div className="col col-md-8  col-lg-6">
                <p>
                  Please enter the email address you registered with website
                  below and we'll email you a link to a page where you can
                  easily create a new password.
                </p>
                <form method="POST" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={this.state.email}
                      className="form-control input-lg"
                      onChange={this.handleChange}
                      placeholder="Email Address"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg mt-3 mb-5"
                  >
                    {this.state.isLoading && (
                      <i className="fa  fa-circle-o-notch fa-spin px-2"></i>
                    )}
                    Continue
                  </button>
                </form>
                <div> </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(ForgetPasswordForm);
