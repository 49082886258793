import React, { Component, Fragment } from "react";
import { compose, withProps, withStateHandlers } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

import { Link } from "react-router-dom";
export default class Map extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.maps !== nextProps.maps) {
      return true;
    } else {
      return false;
    }
  }

  showInfo = (i) => {
    this.setState({
      showInfoIndex: i,
    });
  };
  render() {
    const MyMapComponent = compose(
      withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" +
          process.env.REACT_APP_GOOGLE_KEY +
          "&sensor=false",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: (
          <div
            style={{
              width: "100%",
              marginLeft: 0,
            }}
          />
        ),
        mapElement: <div style={{ height: `100%` }} />,
      }),
      withStateHandlers(
        () => ({
          isOpen: false,
          infoIndex: null,
        }),
        {
          showInfo: ({ isOpen, infoIndex }) => (index) => ({
            isOpen: infoIndex !== index || !isOpen,
            infoIndex: index,
          }),
        }
      ),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap
        defaultZoom={12}
        defaultCenter={{
          lat: 27.7172,
          lng: 85.324,
        }}
      >
        {props.isMarkerShown &&
          this.props.maps.map((item, index) => {
            return (
              <Fragment key={item.vehicle_id}>
                <Marker
                  key={item.id}
                  position={{
                    lat: Number(item.latitude),
                    lng: Number(item.longitude),
                  }}
                  onClick={() => props.showInfo(index)}
                >
                  {props.isOpen && props.infoIndex === index && (
                    <InfoWindow
                      position={{
                        lat: Number(item.latitude),
                        lng: Number(item.longitude),
                      }}
                      onCloseClick={props.showInfo}
                    >
                      <Link to={"/vehicle/view/" + item.vehicle_id}>
                        <h6>{item.vehicle_title}</h6>
                        <div className="item-location">
                          <i className="fa fa-map-marker" />{" "}
                          {item.vehicle_address},{item.vehicle_district}
                        </div>
                      </Link>
                    </InfoWindow>
                  )}
                </Marker>
              </Fragment>
            );
          })}
      </GoogleMap>
    ));
    return <MyMapComponent isMarkerShown />;
  }
}
