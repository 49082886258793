import React, { Component, Fragment } from "react";
import Footer from "../../partials/Footer";
import Navbar from "../../partials/Navbar";
import { Link } from "react-router-dom";
export default class Error extends Component {
  render() {
    return (
      <Fragment>
        <Navbar />
        <div id="content">
          <div className="container-fluid col-md-11">
            <div className="row justify-content-md-center">
              <div className="col col-lg-8">
                <div
                  className="error-template text-center mt-3"
                  style={{ height: "500px" }}
                >
                  <i
                    className="fa fa-exclamation-triangle fa-5x text-danger animated zoomIn mb50"
                    style={{ fontSize: 100 }}
                  />
                  <h3 className="main-title">
                    <span>Oops! 404 Not Found</span>
                  </h3>
                  <div className="main-title-description">
                    Sorry, an error has occured, Requested page not found!
                  </div>
                  <div className="error-actions">
                    <Link
                      to="/"
                      className="btn btn-primary btn-lg ml-2 mr-2 mb-5"
                    >
                      Take me Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
