import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "../src/index.css";
import "swiper/css/swiper.css";
import "react-image-gallery/styles/css/image-gallery.css";
import ScrollToTop from "./components/utilities/ScrollToTop";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store/index";
import axios from "axios";
import cookie from "js-cookie";
import { Provider } from "react-redux";
import { Spinner } from "react-bootstrap";
import SEO from "./SEO";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./assets/css/animate.css";
import "./assets/css/aos.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/selectric.css";
import "./assets/css/selectric.css";
import "./assets/css/style.css";
export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const token = cookie.get("token");
const render = () =>
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <SEO />
          <App />
        </ScrollToTop>
      </Router>
    </Provider>,
    document.getElementById("root")
  );
axios
  .get(process.env.REACT_APP_API + "content")
  .then((response) => {
    store.dispatch({
      type: "SET_CONTENT",
      payload: response.data.data,
    });
    if (token) {
      <Spinner />;
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios.get(process.env.REACT_APP_API + "user").then((response) => {
        store.dispatch({
          type: "SET_LOGIN",
          payload: response.data.firstname,
        });
        render();
      });
    } else {
      render();
    }
  })
  .catch((error) => {
    ReactDOM.render(
      <p>
        Sorry, we are having connectivity issues with the server.Please refresh
        your page or try again later.
      </p>,
      document.getElementById("root")
    );
  });
