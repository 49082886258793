import React, { Component } from "react";
import Axios from "axios";
import Sidebar from "./Sidebar";
import Moment from "react-moment";
import { Spinner } from "../utilities/Spinner";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
class NewsSingleBody extends Component {
  constructor(props) {
    super(props);
    this.state = { news: [], isLoading: false };
  }
  componentDidMount() {
    this.getNews();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.getNews();
    }
  }
  getNews = () => {
    this.setState({ isLoading: true });
    Axios.get(process.env.REACT_APP_API + "news/" + this.props.match.params.id)
      .then((response) => {
        console.log(response);
        this.setState({
          isLoading: false,
          news: response.data.data,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };
  render() {
    return (
      <div id="content">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col col-lg-12 col-xl-12">
              {this.state.isLoading ? (
                <Spinner />
              ) : (
                <div className="row has-sidebar">
                  <div className="col-md-9 col-lg-9 col-xl-9">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/news">News</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {this.state.news.title}
                        </li>
                      </ol>
                    </nav>
                    <div className="page-header v2 bordered">
                      <h1>{this.state.news.title}</h1>
                    </div>
                    <div className="clearfix" />
                    <img
                      src={
                        process.env.REACT_APP_BACKEND_CONTENT +
                        "newsevents/" +
                        this.state.news.image
                      }
                      alt=""
                      className="img-fluid"
                      style={{ height: "500px", width: "100%" }}
                    />
                    <div className="item-meta-info">
                      <span className="date">
                        Posted on:
                        <Moment format="YYYY/MM/DD">
                          {this.state.news.created_at}
                        </Moment>
                      </span>
                    </div>
                    <div
                      className="item-detail mb-5"
                      dangerouslySetInnerHTML={{
                        __html: this.state.news.description,
                      }}
                    ></div>
                  </div>
                  <div className="col-md-3 col-lg-3 col-xl-3 col-sidebar">
                    <Sidebar />
                  </div>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(NewsSingleBody);
